// SortableItem.js
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { ListItem } from '@mui/material';
import ListItemSidebar from './ListItem';
import { useTheme } from '@mui/material/styles';

const SortableItem = ({ id, data }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const theme = useTheme()
  const translateY = transform ? transform.y : 0;
  const style = {
    transform: `translateY(${translateY}px)`,
    transition,
    opacity: isDragging ? 0.5 : 1,
    // background: '#fff',
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    margin: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    cursor: 'grab',
  };

  const downnloadFunction = data.type==="vector"?data.handleDownload:data.handleDownloadRaster

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      component="div"
      sx={{
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        }
      }}
    >
      
      <ListItemSidebar
        datasets={data.type === 'vector' ? data.parentVectors : data.parentRasters}
        setDatasets={data.type === 'vector' ? data.setVectors : data.setRasters}
        dataset={data.item}
        datatype={data.type === 'vector' ? 'geojson' : 'raster'}
        zoomToLayer={
          data.type === 'vector'
            ? () => data.zoomToLayer(data.item.data.properties.id, data.geojsonLayerRefs, data.mapInstance)
            : () => data.zoomToLayerRaster(data.item.data.id,data.parentRasters, data.mapInstance)
        }
        updateStyle={data.updateStyle}
        updateStyleCat={data.updateStyleCat}
        // selectedFeatureAttributes={data.selectedFeatureAttributes}
        inmemory={data.inmemory}
        changeStyleData={data.changeStyleData}
        setChangeStyleData={data.setChangeStyleData}
        handleDownload={downnloadFunction} //data.handleDownload}
        handleDownloadSelected={data.handleDownloadSelected}
        selectedFeatures={data.selectedFeatures}
        setSelectedFeatures={data.setSelectedFeatures}
        isSharedView={data.isSharedView}
      />
    </ListItem>
  );
};

export default SortableItem;
