import React, { useState, useEffect } from 'react';
import { setSharedData } from './utils/ProjectFunctions';
import { useParams } from 'react-router-dom';
import { MapComponent } from './utils/MapComponent';
import { loadingPage } from './utils/Loading';
import axiosHttp from '../utils/axios';
import NotFound from './NotFound';
import "../styles/Project.css";

const API_URL = process.env.REACT_APP_API_URL;

function SharedView() {
    const [project, setProject] = useState(null);
    const [rasters, setRasters] = useState([]);
    const [vectors, setVectors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSharedView,setIsSharedView] = useState(false);
    const { token } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (token) {
                    const response = await axiosHttp.get(
                        `${API_URL}/api/main/share/${token}/`
                    );
                    if (!project) {
                        await setSharedData(response.data, setProject, setRasters, setVectors);
                        setIsSharedView(true)
                    }
                } else {
                    console.log("GOT HERE INSIDE THE FUNCTION")
                    return <NotFound/>
                }
            } catch (error) {
                console.error('Error loading shared project:', error);
            }
            setIsLoading(false);
        };

        if (!project) {
            setIsLoading(true);
            fetchData();
            setIsLoading(false);
        }
    }, [token, project]);

    return (
        <>
            {isLoading ? (
                loadingPage()
                ) : 
                
                (   !isSharedView?
                    <NotFound/>:
                    (
                    <MapComponent
                        rasters={rasters}
                        setRasters={setRasters}
                        vectors={vectors}
                        setVectors={setVectors}
                        projectid={null}
                        project={null}
                        savetomemory={true}
                        isSharedView={isSharedView}
                    />
                ))
                
                
            }
        </>
    );
}

export default SharedView;
