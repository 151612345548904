// import React, { useEffect } from 'react';
// import "./NavbarNew.css"

// // source: https://codepen.io/RSH87/full/rmgYbo
// // source: https://themewagon.com/blog/responsive-navbar-collection/
import React, { useEffect } from 'react';
import { IconButton, Box } from '@mui/material';
import "./NavbarNew.css"

const Menu = ({ navActive, setNavActive, showLoginForm }) => {

    const links =process.env.NODE_ENV==="development"? [
        { label: 'Map', link: '/map' },
        { label: 'Pricing', link: '/plans' },
        { label: 'Contact', link: '/contact' },
        { label: 'About', link: '/about' },
    ]:[
        { label: 'Map', link: '/map' },
        { label: 'Contact', link: '/contact' },
        { label: 'About', link: '/about' },
    ]

    useEffect(() => {
        const body = document.body;
        if (navActive) {
            body.classList.add('nav-active');
        } else {
            body.classList.remove('nav-active');
        }

        return () => body.classList.remove('nav-active');
    }, [navActive]);

    return (
        <>
            {!showLoginForm && (
                <IconButton
                    onClick={() => setNavActive(!navActive)}
                    disableRipple
                    disableFocusRipple
                    sx={{
                        position: 'fixed',
                        zIndex: 2,
                        left: '50px',
                        top: '30px',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <Box
                        sx={{
                            height: '2px',
                            width: '30px',
                            backgroundColor: navActive ? '#000000' : 'white',
                            transition: 'transform 0.2s ease, background-color 1s ease',
                            transform: navActive ? 'translateX(1px) rotate(-45deg)' : 'none',
                            position: 'relative',
                        }}
                    >
                        <Box
                            sx={{
                                height: '2px',
                                width: '15px',
                                backgroundColor: navActive ? '#000000' : '#ffffff',
                                position: 'absolute',
                                top: '-6px',
                                left: '0',
                                transform: navActive ? 'translateX(9px) rotate(90deg)' : 'none',
                                transition: 'transform 0.2s ease',
                            }}
                        />
                        <Box
                            sx={{
                                height: '2px',
                                width: '15px',
                                backgroundColor: navActive ? '#000000' : '#ffffff',
                                position: 'absolute',
                                bottom: '-6px',
                                right: '0',
                                transform: navActive ? 'translateX(-6px) rotate(90deg)' : 'none',
                                transition: 'transform 0.2s ease',
                            }}
                        />
                    </Box>
                </IconButton>
            )}

            <div className="nav">
                <div className="nav__content">
                    <ul className="nav__list">
                        {links.map((link) => (
                            <li
                                key={link.label}
                                className="nav__list-item"
                                onClick={() => setNavActive(false)}
                            >
                                <a href={link.link} className="nav__link">
                                    {link.label}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Menu;
// import React, { useState } from 'react';
// import './Navbar.css'; // Add this file for custom CSS styles
// import { Box, Typography, IconButton, List, ListItem } from '@mui/material';

// const Navbar = ({navActive,setNavActive,showLoginForm}) => {

//     const toggleNav = () => {
//         setNavActive(!navActive);
//     };

    // const links = [
    //     { label: 'Map', link: '/map' },
    //     { label: 'Pricing', link: '/plans' },
    //     { label: 'Contact', link: '/contact' },
    //     { label: 'About', link: '/about' },
    // ]

//     return (
//         <Box
//             sx={{
//                 height: navActive?'100vh':0,
//                 margin: '0 auto',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 position: 'relative',
//             }}
//         >
//             {!showLoginForm?
//             <IconButton
//                 onClick={toggleNav}
//                 disableRipple
//                 disableFocusRipple
//                 sx={{
//                     position: 'fixed',
//                     zIndex: 2,
//                     left: '50px',
//                     top: '30px',
//                     cursor: 'pointer',
//                     boxShadow: 'none',
//                     backgroundColor: 'transparent', 
//                     '&:hover': {
//                         backgroundColor: 'transparent', 
//                     },
//                 }}
//             >
//                 <Box
//                     sx={{
//                         height: '2px',
//                         width: '30px',
//                         backgroundColor: navActive ? '#000000' : 'white',
//                         transition: 'transform 0.2s ease, background-color 1s ease',
//                         transform: navActive ? 'translateX(1px) rotate(-45deg)' : 'none',
//                         position: 'relative',
//                     }}
//                 >
//                     <Box
//                         sx={{
//                             height: '2px',
//                             width: '15px',
//                             backgroundColor: navActive ? '#000000' : '#ffffff',
//                             position: 'absolute',
//                             top: '-6px',
//                             left: '0',
//                             transform: navActive ? 'translateX(9px) rotate(90deg)' : 'none',
//                             transition: 'transform 0.2s ease',
//                         }}
//                     />
//                     <Box
//                         sx={{
//                             height: '2px',
//                             width: '15px',
//                             backgroundColor: navActive ? '#000000' : '#ffffff',
//                             position: 'absolute',
//                             bottom: '-6px',
//                             right: '0',
//                             transform: navActive ? 'translateX(-6px) rotate(90deg)' : 'none',
//                             transition: 'transform 0.2s ease',
//                         }}
//                     />
//                 </Box>
//             </IconButton>:null}

//             {/* <Box
//                 sx={{
//                     position: 'fixed',
//                     zIndex: 1,
//                     top: 0,
//                     left: 0,
//                     width: '100vw',
//                     height: '100vh',
//                     backgroundColor: navActive ? 'rgba(234, 234, 234, 0.2)' : 'transparent',
//                     transition: 'transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s',
//                     transform: navActive ? 'translateY(0)' : 'translateY(-100%)',
//                 }}
//             /> */}

//             <Box
//                 sx={{
//                     position: 'fixed',
//                     zIndex: 2, 
//                     top: '50%',
//                     left: '50%',
//                     transform: 'translate(-50%, -50%)',
//                     textAlign: 'center',
//                     pointerEvents: navActive ? 'auto' : 'none',
//                     opacity: navActive ? 1 : 0,
//                     transition: 'opacity 1s ease',
                    
                    
//                 }}
//             >
//                 <List>
//                     {links.map((item, index) => (
//                         <ListItem
//                             key={item.label}
//                             sx={{
//                                 fontSize: 'calc(2vw + 10px)',
//                                 fontWeight: 200,
//                                 opacity: navActive ? 1 : 0,
//                                 transform: navActive ? 'translateY(0)' : 'translateY(100%)',
//                                 transition: `opacity 1s ease ${0.3 + index * 0.1}s, transform 0.3s ease`,
//                                 marginBottom: '20px',
//                             }}
//                             component="a"
//                             href={item.link}
//                         >
//                             {item.label}
//                         </ListItem>
//                     ))}
//                 </List>
//             </Box>

//         </Box>
//     );
// };

// export default Navbar;
