import { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Box,
    LinearProgress,
    Grid,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CheckCircle, AccountTree, Event, Star } from '@mui/icons-material';
import Cookies from 'js-cookie';
import axiosHttp from "../../../utils/axios";

const formatDataSize = (sizeInBytes) => {
    if (!sizeInBytes) return '0 B';
    const units = ['B', 'KB', 'MB', 'GB'];
    let unitIndex = 0;
    while (sizeInBytes >= 1024 && unitIndex < units.length - 1) {
        sizeInBytes /= 1024;
        unitIndex++;
    }
    return `${sizeInBytes.toFixed(unitIndex === 0 ? 0 : 2)} ${units[unitIndex]}`;
};

export const getDataUsage = async () => {
    try {
        const accessToken = Cookies.get('access_token');
        return await axiosHttp.get(
            `${process.env.REACT_APP_API_URL}api/file_manager/get_usage/`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );
    } catch (error) {
        console.error('Error fetching usage:', error);
        throw error;
    }
};


const getPlans = async (setError) => {
    try {
        const response = await axiosHttp.get(`${process.env.REACT_APP_API_URL}api/plans/plans/`, {
            headers: {
                Authorization: `Bearer ${Cookies.get('access_token')}`,
                },
            })
            if (response.status === 200) 
                return response.data            
            else {
                setError(response.message)
            }
    } catch (error) {
        setError(error);
    } 
};



export const ProfilePlan = ({ user, profile, membership }) => {
    const [dataUsage, setDataUsage] = useState({});
    const [plans,setPlans] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { t } = useTranslation();
    const [hoveredPlan, setHoveredPlan] = useState(null);
    
    const sortedPlans = [...plans].sort((a, b) => a.price - b.price);

    const calculatePercentage = (used, total) => {
        return total > 0 ? Math.min((used / total) * 100, 100) : 0;
    };

    useEffect(() => {
        const fetchDataUsage = async () => {
            try {
                const response = await getDataUsage();
                if (response.status === 200) {
                    setDataUsage(response.data);
                }
            } catch (error) {
                setError(t('error_fetching_usage'));
            } finally {
                setLoading(false);
            }
        };
        fetchDataUsage();
    }, [t]);


    useEffect(() => {
        const fetchPlans = async () => {
            setLoading(true);
            const data = await getPlans(setError);
            if (data) setPlans(data);
            setLoading(false);
        };

        fetchPlans();
    }, []);

    const handleChangePlan = (e)=>{

        console.log(e)
    }
    

    const renderUsageItem = (label, used, totalBytes, totalPlan) => {
        const hasLimit = totalPlan > 0;
        const percentage = hasLimit ? Math.min(calculatePercentage(used, totalBytes), 100) : 0;
        
        let progressColor = '#4CAF50';
        if (percentage > 90) {
            progressColor = '#D32F2F';
        } else if (percentage > 80) {
            progressColor = '#FFA000';
        }
    
        return (
            <Box sx={{ mb: 3 }}>
                <Typography variant="body2" gutterBottom>
                    {label} ({formatDataSize(used)}
                    {hasLimit && ` / ${formatDataSize(totalBytes)}`}
                    {!hasLimit && ` (${t('unlimited')})`}
                    ) - <strong>{percentage.toFixed(1)}%</strong>
                </Typography>
                {hasLimit && (
                    <LinearProgress
                        variant={loading ? 'indeterminate' : 'determinate'}
                        value={loading ? 0 : percentage}
                        sx={{ 
                            height: 8, 
                            borderRadius: 4, 
                            backgroundColor: "lightgray",
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: progressColor
                            }
                        }}
                    />
                )}
            </Box>
        );
    };
    

    if (error) {
        return (
            <Card sx={{ maxWidth: 800, margin: 'auto', p: 3 }}>
                <CardContent>
                    <Typography color="error">{error}</Typography>
                </CardContent>
            </Card>
        );
    }

    return (
        <>
            {sortedPlans.map((plan) => {
                const isCurrentPlan = plan.id === membership.plan.id;
                const isHigher = plan.price > membership.plan.price;
                const buttonText = isHigher ? t('upgrade') : t('downgrade');

                return (
                    <div 
                        key={plan.id} 
                        style={{ padding: 10, position: 'relative' }}
                        onMouseEnter={() => setHoveredPlan(plan.id)}
                        onMouseLeave={() => setHoveredPlan(null)}
                    >
                        <Card 
                            sx={{ 
                                maxWidth: 800, 
                                margin: 'auto', 
                                p: 3, 
                                position: 'relative',
                                filter: !isCurrentPlan ? 'blur(3px)' : 'none',
                                opacity: !isCurrentPlan ? 0.7 : 1
                            }}
                        >
                            <CardContent>
                                {isCurrentPlan && (
                                    <Chip
                                        label={t('current_plan')}
                                        color="primary"
                                        sx={{ position: 'absolute', top: 10, right: 10, fontWeight: 'bold' }}
                                    />
                                )}

                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                            <Star sx={{ fontSize: 40, mr: 2, color: isCurrentPlan ? 'primary.main' : 'action.disabled' }} />
                                            <Box>
                                                <Typography variant="h5">{plan.name}</Typography>
                                                {plan.price && (
                                                    <Typography variant="subtitle1" color="text.secondary">
                                                        ${plan.price}/{plan.interval?.toLowerCase()}
                                                    </Typography>
                                                )}
                                            </Box>
                                        </Box>

                                        {isCurrentPlan && membership?.status && (
                                            <Chip
                                                label={membership.status}
                                                color={membership.status === 'ACTIVE' ? 'success' : 'warning'}
                                                variant="outlined"
                                                sx={{ mb: 2 }}
                                            />
                                        )}

                                        <List dense>
                                            <ListItem>
                                                <ListItemIcon>
                                                    <CheckCircle color="primary" />
                                                </ListItemIcon>
                                                <ListItemText primary={t('plan_status')} secondary={membership?.status || t('active')} />
                                            </ListItem>

                                            <ListItem>
                                                <ListItemIcon>
                                                    <Event color="primary" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={t('next_billing_date')}
                                                    secondary={membership?.next_billing_date ?
                                                        new Date(membership.next_billing_date).toLocaleDateString() :
                                                        t('no_upcoming_billing')}
                                                />
                                            </ListItem>
                                        </List>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6" gutterBottom>
                                            {t('usage_details')}
                                        </Typography>

                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <>
                                                {renderUsageItem(t('raster_storage'), profile?.total_raster_usage || 0, (plan.raster_storage || 0) * 1024 * 1024, plan.raster_storage || 0)}
                                                {renderUsageItem(t('vector_storage'), profile?.total_vector_usage || 0, (plan.vector_storage || 0) * 1024 * 1024, plan.vector_storage || 0)}
                                                {renderUsageItem(t('data_transfer'), dataUsage?.total || 0, (membership?.plan?.data_transfer || 0) * 1024 * 1024, membership?.plan?.data_transfer || 0)}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>

                                <Divider sx={{ my: 3 }} />

                                <Typography variant="body2" color="text.secondary">
                                    {plan.description || t('default_plan_description')}
                                </Typography>
                            </CardContent>
                        </Card>

                        {!isCurrentPlan && hoveredPlan === plan.id && (
                            <Button
                                variant="contained"
                                color={isHigher ? 'primary' : 'secondary'}
                                sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}
                                onClick={handleChangePlan}
                            >
                                {buttonText}
                            </Button>
                        )}
                    </div>
                );
            })}
        </>
    );
};
