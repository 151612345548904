import React, { useState, useEffect, useRef, useMemo,useCallback } from 'react';
import tileLayersData from '../../configs/tileLayers.json';
import '../../styles/MapComponent.css'
import 'leaflet/dist/leaflet.css';
import {
  MapContainer,
  TileLayer,
  ZoomControl,
  GeoJSON,
  ImageOverlay,
  FeatureGroup,
  Pane
} from 'react-leaflet';
import BasemapSelector from './BasemapSelector';
import L from 'leaflet';
import { handleDropGeojson } from './eventHandler';
import { useDispatch } from 'react-redux';
import { UploadToMemoryDrop } from './Memory/eventHandlers';
import SideNav from './Sidebar';
import ShareButton from './ShareButton';
import AttributesModal from './ModalAttributeFeature';
import MapConfigModal from './MapConfigModal';
import IconButton from '@mui/material/IconButton';
import useDownloadSelected from './useDownloadSelected';
import CaptureMapImage from './CaptureMapImage';
import useDownload from './useDownload';
import TakeScreenshot from './TakeScreenshot';
import useFeatureHandler from './useFeatureHandle';
import { AttributeTableModal } from './AttibuteTable';
import PrintIcon from '@mui/icons-material/Print';
import useDownloadRaster from './useDownloadRaster';
import { loadingPage } from './Loading';

export const MapComponent = ({
  rasters,
  setRasters,
  vectors,
  setVectors,
  projectid = null,
  project = null,
  savetomemory = true,
  isSharedView
}) => {
  const [loading, setLoading] = useState(true);
  const [selectedTileLayer, setSelectedTileLayer] = useState(tileLayersData[0].url);
  const [mapInstance, setMapInstance] = useState(null);
  const [selectedFeatureAttributes, setSelectedFeatureAttributes] = useState(null);
  const [modalData, setModalData] = useState([]);
  const [modalFeatureId, setModalFeatureId] = useState([]);
  const [modalVectorId, setModalVectorId] = useState([]);
  
  const [uploading, setUploading] = useState(false)
  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [changeStyleData, setChangeStyleData] = useState(null)
  const [sideNavExpanded, setSideNavExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rastersOnTop,setRastersOnTop] = useState(false)

  const layerRefs = useRef({})
  const featureGroupRef = useRef(new L.FeatureGroup());
  const mapRef = useRef(null);
  const [mapTitle, setMapTitle] = useState('');
  const [showNorthArrow, setShowNorthArrow] = useState(false);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);
  const geojsonLayerRefs = useRef({});
  const defaultCenter = useMemo(() => [50.640, 10.553], []);
  const defaultZoom = 5;
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [mapZoom, setMapZoom] = useState(defaultZoom);
  const [ticking, setTicking] = useState(true)
  const [count, setCount] = useState(0)
  const [logoUrl, setLogoUrl] = useState('');
  const [description, setDescription] = useState('');
  const handleDownloadSelected = useDownloadSelected(geojsonLayerRefs, selectedFeatures);
  const captureMapImage = CaptureMapImage({ mapTitle, showNorthArrow, logoUrl, description, rasters, vectors });
  const handleDownload = useDownload();
  const handleDownloadRaster = useDownloadRaster()
  const takeScreenshot = TakeScreenshot({ mapInstance, projectid, count, setCount });
  const [captureTrigger, setCaptureTrigger] = useState(0);
  const { onEachFeatureVector } = useFeatureHandler({
    vectors,
    layerRefs,
    setSelectedFeatures,
    setSelectedFeatureAttributes,
    setModalData,
    setModalFeatureId,
    setModalVectorId,
    setIsModalOpen,
  });
  const dispatch = useDispatch()


  useEffect(() => {
    if (rasters && vectors) {
      setLoading(false);
    }
  }, [rasters, vectors]);


  useEffect(() => {
    if (!mapInstance) return;

    const handleMoveEnd = () => {
      setMapCenter(mapInstance.getCenter());
      setMapZoom(mapInstance.getZoom());
    };

    mapInstance.on('moveend', handleMoveEnd);

    return () => {
      mapInstance.off('moveend', handleMoveEnd);
    };
  }, [mapInstance]);

  useEffect(() => {
    if (project && mapInstance) {
      let center = defaultCenter;
      let zoom = defaultZoom;

      if (project.centerCoordinate && !(project.bounds.minLat === Infinity || project.bounds.maxLat === -Infinity)) {
        const { minLat, maxLat, minLng, maxLng } = project.bounds;
        center = [(minLat + maxLat) / 2, (minLng + maxLng) / 2];
        mapInstance.fitBounds([[minLat, minLng], [maxLat, maxLng]]);
        zoom = mapInstance.getBoundsZoom([[minLat, minLng], [maxLat, maxLng]]);
      }

      mapInstance.setView(center, zoom);
    }
  }, [project, mapInstance, defaultCenter, defaultZoom]);

  //TODO: Add more things here on saving. For example:
  // - position
  // - zoom level
  // - which file is enable/disabled
  // - which basemap is selected
  // - 
  const handleSaveClick = useCallback(() => {
    takeScreenshot();
  }, [takeScreenshot])

  useEffect(() => {
    const adjustZoomControlPosition = () => {
      const zoomControl = document.querySelector('.leaflet-control-zoom');
      if (zoomControl) {
        zoomControl.style.left = sideNavExpanded ? '360px' : '60px'; // Ajusta com base na expansão do sidenav
      }
    };

    adjustZoomControlPosition();
  }, [sideNavExpanded]);

  useEffect(() => {
    if (captureTrigger > 0) {
      const timeoutId = setTimeout(() => {
        captureMapImage();
      }, 0);

      return () => clearTimeout(timeoutId);
    }
  }, [captureTrigger]);

  const flattenedData = modalData.flat();
  const uniqueKeys = Array.from(new Set(flattenedData.flatMap(Object.keys)));

  const handleDragOver = (e) => {
    e.preventDefault();
  };



  const handleDrop = (e) => {
    console.log('drop')
    if (!savetomemory) {
      handleDropGeojson(e, 
        setVectors, 
        setRasters, 
        mapInstance, 
        dispatch, 
        projectid, 
        setLoading
      )
    } else {
      UploadToMemoryDrop(e, setVectors, mapInstance)
    }
  }

  const handleOpenConfigModal = () => {
    setIsConfigModalOpen(true);
  };

  const handleCloseConfigModal = () => {
    setIsConfigModalOpen(false);
  };

  const handleApplySettings = ({ title, showNorthArrow, previewCenter, previewZoom, logoUrl, description }) => {
    setMapTitle(title);
    setShowNorthArrow(showNorthArrow);
    setLogoUrl(logoUrl);
    setDescription(description);

    if (mapInstance) {
      mapInstance.setView(previewCenter, previewZoom);
    }

    // Increment captureTrigger to force useEffect to run
    setCaptureTrigger((prev) => prev + 1);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const vectorStyle = (feature, vector) => {
    const selected = vector.data.features.find(
      (v) => v.id === feature.id
    );

    if (!selected) {
      return
    }

    const featureId = feature.id || feature.properties.id;
    const isSelected = selectedFeatures.includes(featureId);

    return isSelected
      ? { color: 'yellow', fillColor: "yellow" }
      : selected.style
  }

  const MapItem = (
    <div
      // onDrop={handleDrop}
      // onDragOver={handleDragOver}
      style={{ width: '100%', height: '500px' }}
      ref={mapRef}
    >
      <MapContainer
        className='map-container shrink'
        // ref={(map) => {if (map && !mapInstance) {setMapInstance(map)}}}
        ref={(map) => { if (map) { setMapInstance(map)} }}
        center={defaultCenter}
        zoom={defaultZoom}
        zoomControl={false}
        maxZoom={22}
        minZoom={2}
      >

        <TileLayer url={selectedTileLayer} />

        {rasters.map((rasterdata, index) => {
          const raster = rasterdata.data
          return rasterdata.visible && (
            <ImageOverlay
              url={raster.png}
              bounds={rasterdata.bounds}
              opacity={rasterdata.style.opacity}
              key={index}
              ref={(layer) => {
                if (layer) {
                  rasters[index].layerRef = layer;
                }
              }}
            />
          );
        })}

        {vectors.map((vector, index) => {
          return vector.visible &&
            (<GeoJSON
              key={`vector-${index}`}
              ref={(el) => {
                if (el) {
                  geojsonLayerRefs.current[vector.data.properties.id] = el;
                }
              }}
              data={vector.data}
              style={(feature) => vectorStyle(feature, vector)}
              onEachFeature={onEachFeatureVector(vector.data)}
              pointToLayer={(feature, latlng) => {
                if (feature.geometry.type === 'Point' || feature.geometry.type === 'MultiPoint') {
                  return L.circleMarker(latlng, vectorStyle(feature, vector));
                }
                return L.marker(latlng);
              }}
            >
            </GeoJSON>)
        })}

        <FeatureGroup ref={featureGroupRef} />
        <ZoomControl position="bottomleft" className="custom-buttom-zoom" />
      </MapContainer>
    </div>
  )

  if (loading && !mapInstance) {
    return loadingPage();
  }

  return (
    <>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}>
      
      <SideNav
        rasters={rasters}
        setRasters={setRasters}
        vectors={vectors}
        setVectors={setVectors}
        geojsonLayerRefs={geojsonLayerRefs}
        mapInstance={mapInstance}
        projectid={projectid}
        setUploading={setLoading}
        selectedFeatureAttributes={selectedFeatureAttributes}
        inmemory={savetomemory}
        changeStyleData={changeStyleData}
        setChangeStyleData={setChangeStyleData}
        handleDownload={handleDownload}
        handleDownloadRaster={handleDownloadRaster}
        handleDownloadSelected={handleDownloadSelected}
        featureGroupRef={featureGroupRef}
        open={sideNavExpanded}
        setOpen={setSideNavExpanded}
        selectedFeatures={selectedFeatures}
        setSelectedFeatures={setSelectedFeatures}
        rastersOnTop={rastersOnTop}
        setRastersOnTop={setRastersOnTop}
        handleSaveClick={handleSaveClick}
        isSharedView={isSharedView}
      />

      {MapItem}
      <div
        style={{
          position: 'absolute',
          bottom: '250px',
          left: sideNavExpanded ? '420px' : '120px', 
          transition: 'left 0.3s ease-in-out',
          zIndex: 1000,
        }}
      >
        <div style={{ position: 'absolute', top: '10px', right: '10px', zIndex: 1000 }}>
          <IconButton
            variant="contained"
            color="primary"
            onClick={handleOpenConfigModal}
            style={{
              backgroundColor: '#fff',
              boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
              color: '#000',
              borderRadius: '8px',
              width: '40px',
              height: '40px',
            }}
          >
            <PrintIcon />
          </IconButton>
        </div >
      </div>

      <MapConfigModal
        open={isConfigModalOpen}
        onClose={handleCloseConfigModal}
        onApply={handleApplySettings}
        currentMapState={{
          center: mapCenter,
          zoom: mapZoom,
          tileLayerUrl: selectedTileLayer,
        }}
        rasters={rasters}
        vectors={vectors}
        selectedFeatures={selectedFeatures}
      />

      <div
        style={{
          position: 'absolute',
          bottom: '200px',
          left: sideNavExpanded ? '420px' : '120px',
          transition: 'left 0.3s ease-in-out',
          zIndex: 1000,
        }}
      >
        <BasemapSelector
          setSelectedTileLayer={setSelectedTileLayer}
          tileLayersData={tileLayersData}
          sideNavExpanded={sideNavExpanded}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          bottom: '150px',
          left: sideNavExpanded ? '420px' : '120px', // Adjust based on the expanded state
          transition: 'left 0.3s ease-in-out', // Smooth transition
          zIndex: 1000,
        }}
      >
        {!isSharedView && projectid && (
          <ShareButton projectId={projectid} />
        )}
      </div >

      <AttributesModal
        open={isModalOpen}
        onClose={handleModalClose}
        modalData={modalData}
        setModalData={setModalData}
        // flattenedData={flattenedData} 
        uniqueKeys={uniqueKeys}
        id={modalFeatureId}
        vectorId={modalVectorId}
        setVectors={setVectors}
        inmemory={savetomemory}
      />

      <div id="change-style-modal" className="modal">
        <div className="modal-content">
          {changeStyleData}
        </div>
        <div className="modal-footer">
          <a href="#!" className="modal-close waves-effect waves-green btn-flat">Fechar</a>
        </div>
      </div>
      </div>
    </>
  );
};
