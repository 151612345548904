import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, IconButton, List, ListItem, Button } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { useSelector } from "react-redux";
import "leaflet/dist/leaflet.css";
import attractions from "./touristic_attractions";
import L from "leaflet";
import Menu from "./include/NavbarNew";
import Project from "./Project";
import LoadingPage from "./LoadingPage";
import LoginForm from "./LoginForm";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import "../styles/Homepage.css";

const customIcon = new L.Icon({
    iconUrl: "https://img.icons8.com/?size=100&id=7880&format=png&color=FF0000",
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
});

const Homepage = () => {
    const { isAuthenticated, user, loading } = useSelector((state) => state.user);
    const [currentAttractionIndex, setCurrentAttractionIndex] = useState(0);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [playAnimation,setPlayAnimation] = useState(true)
    const [hoverSignIn, setHoverSignIn] = useState(false);
    const [loginSucess,setLoginSuccess] = useState(false)
    const mapRef = useRef(null);
    const [navActive, setNavActive] = useState(false);

    const intervalTime = 5000;

    useEffect(() => {
        let interval;
        if (playAnimation) {
            interval = setInterval(() => {
                setCurrentAttractionIndex(
                    (prevIndex) => (prevIndex + 1) % attractions.length
                );
            }, intervalTime);
        }

        return () => clearInterval(interval);
    }, [playAnimation, attractions.length]);

    useEffect(() => {
        if (navActive) {
            document.body.classList.add('nav-active');
        } else {
            document.body.classList.remove('nav-active');
        }
        return () => document.body.classList.remove('nav-active');
    }, [navActive]);

    const currentAttraction = attractions[currentAttractionIndex];

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.flyTo(currentAttraction.coordinates, 14, {
                animate: true,
                duration: 3,
            });
        }
    }, [currentAttraction]);

    const handleMouseEnterSignIn = () => {
        setHoverSignIn(true);
    };

    const handleMouseLeaveSignIn = () => {
        setHoverSignIn(false);
    };

    const url = process.env.PUBLIC_URL;

    if (loginSucess){
        return <Project />;
    }

    if (loading) {
        return <LoadingPage />;
    }

    if (isAuthenticated && user && !loading) {
        return <Project />;
    } else 
    return (
        <>
            <div style={{
                position: "relative",
                height: "100vh",
                width: "100vw",
                filter: showLoginForm ? "blur(5px)" : "none",
                transition: "filter 0.3s ease",
            }}>
                <Menu navActive={navActive} setNavActive={setNavActive} />

                {navActive ? null : (
                    <>
                        <MapContainer
                            style={{
                                height: "100%",
                                width: "100%",
                                zIndex: 0,
                                filter: showLoginForm ? "blur(5px)" : "none",
                                transition: "filter 0.3s ease",
                            }}
                        center={currentAttraction.coordinates}
                        zoom={14}
                        minZoom={10}
                        zoomControl={false}
                        ref={mapRef}
                    >
                        {

                        }
                        {!hoverSignIn?
                        <TileLayer
                            url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                            subdomains="abcd"
                            maxZoom={20}
                        />:
                        <TileLayer
                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                            subdomains="abcd"
                            maxZoom={20}
                        />
                        }

                            <Marker position={currentAttraction.coordinates} icon={customIcon}>
                                <Popup style={{
                                    background: 'rgba(16, 16, 16, 0.9)',
                                    borderRadius: '12px',
                                    color: '#ffffff',
                                    backdropFilter: 'blur(5px)',
                                }}>
                                    <strong style={{ fontFamily: "'Roboto Condensed', sans-serif" }}>
                                        {currentAttraction.name}
                                    </strong>
                                    <div style={{
                                        margin: '8px 0',
                                        height: '1px',
                                        background: 'rgba(255, 255, 255, 0.2)'
                                    }} />
                                    <div style={{ fontSize: '0.9rem' }}>
                                        {currentAttraction.location}
                                        <br />
                                        <span style={{ color: '#e50914' }}>
                                            Visitors: {currentAttraction.visitors}
                                        </span>
                                    </div>
                                </Popup>
                            </Marker>
                        </MapContainer>

                    <div
                        style={{
                            position: "absolute",
                            top: "10%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1000,
                            textAlign: "center",
                        }}
                    >
                        <img
                            className="img-logo"
                            src={url + "/logo-world.svg"}
                            alt="Web GIS Logo"
                            style={{
                                height: "50px",
                                marginBottom: "30px",
                                filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5))",
                            }}
                        />
                    </div>
                </>
            )}
        </div>

        {navActive ? null : (
                <>
                    {showLoginForm ? (
                        <LoginForm
                        setShow={setShowLoginForm}
                        setLoginSuccess={setLoginSuccess}
                        handleMouseEnterSignIn={handleMouseEnterSignIn}
                        handleMouseLeaveSignIn={handleMouseLeaveSignIn}
                    />
                    ) : (
                        <Box sx={{
                            position: "absolute",
                            top: "80%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            zIndex: 1000,
                            textAlign: "center",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: '8px',
                        }}>
                            <Button
                                variant="contained"
                                onClick={() => setShowLoginForm(true)}
                                sx={{
                                    fontWeight: 600,
                                    letterSpacing: '0.05em',
                                    borderRadius: "30px",
                                    padding: "12px 36px",
                                    background: 'linear-gradient(135deg, #e50914 0%, #b20710 100%)',
                                    color: '#ffffff',
                                    boxShadow: '0 8px 24px rgba(229, 9, 20, 0.3)',
                                    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
                                    '&:hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0 12px 28px rgba(229, 9, 20, 0.4)',
                                        background: 'linear-gradient(135deg, #f40612 0%, #c40812 100%)',
                                    },
                                }}>
                                Login
                            </Button>

                            <Typography sx={{
                                color: "rgba(255, 255, 255, 0.8)",
                                fontSize: "0.9rem",
                                fontWeight: 300,
                                textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
                            }}>
                                or
                            </Typography>

                            <Button
                                variant="contained"
                                href="/map"
                                sx={{
                                    fontWeight: 600,
                                    letterSpacing: '0.05em',
                                    borderRadius: "30px",
                                    padding: "12px 36px",
                                    background: 'rgba(255, 255, 255, 0.1)',
                                    border: '1px solid rgba(255, 255, 255, 0.2)',
                                    color: '#ffffff',
                                    backdropFilter: 'blur(8px)',
                                    boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)',
                                    transition: 'all 0.2s ease',
                                    '&:hover': {
                                        background: 'rgba(255, 255, 255, 0.15)',
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0 12px 28px rgba(0, 0, 0, 0.3)',
                                    },
                                }}>
                                Try it
                            </Button>
                        </Box>
                    )}

                    <Box sx={{
                        position: "absolute",
                        bottom: "24px",
                        left: "24px",
                        zIndex: 1000,
                        '& button': {
                            backdropFilter: 'blur(8px)',
                            background: 'rgba(16, 16, 16, 0.4)',
                            border: '1px solid rgba(255, 255, 255, 0.1)',
                            '&:hover': {
                                background: 'rgba(229, 9, 20, 0.8)',
                            }
                        }
                    }}>
                        <IconButton
                            onClick={() => setPlayAnimation((prev) => !prev)}
                            sx={{ color: '#ffffff' }}>
                            {!playAnimation ? (
                                <PlayCircleOutlineIcon fontSize="large" />
                            ) : (
                                <PauseCircleOutlineIcon fontSize="large" />
                            )}
                        </IconButton>
                    </Box>
                </>
            )}
        {/* {navActive ? null :(
        <>
        {showLoginForm?
            <LoginForm
                setShow={setShowLoginForm}
                setLoginSuccess={setLoginSuccess}
                handleMouseEnterSignIn={handleMouseEnterSignIn}
                handleMouseLeaveSignIn={handleMouseLeaveSignIn}
            />
            :
            <Box
                sx={{
                    position: "absolute",
                    top: "80%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Button
                    variant="contained"
                    onClick={()=>setShowLoginForm(true)}
                    onMouseEnter={handleMouseEnterSignIn}
                    onMouseLeave={handleMouseLeaveSignIn}
                    sx={{
                        fontWeight: 500,
                        borderRadius: "30px",
                        padding: "10px 30px",
                        backgroundColor: "#e50914",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.4)",
                        "&:hover": {
                            backgroundColor: "#f40612",
                        },
                    }}
                >
                    Login
                </Button>

                <Typography
                    sx={{
                        color: "white",
                        fontSize: "1rem",
                        fontWeight: "300",
                    }}
                >
                    or
                </Typography>

                <Button
                    variant="contained"
                    href="/map"
                    sx={{
                        fontWeight: 500,
                        borderRadius: "30px",
                        padding: "10px 30px",
                        backgroundColor: "#e50914",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.4)",
                        "&:hover": {
                            backgroundColor: "#f40612",
                        },
                    }}
                >
                    Try it
                </Button>
            </Box>
            
        }
        </>
        )}

        {navActive ? null :(
            <Box
                sx={{
                    position: "absolute",
                    bottom: "10px",
                    left: "10px",

                    zIndex: 1000,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => setPlayAnimation((prev) => !prev)}
                    sx={{
                        backgroundColor: "rgba(129, 2, 2, 0)",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.4)",
                        "&:hover": {
                            backgroundColor: "#f40612",
                        },
                    }}
                >
                    {!playAnimation?<PlayCircleOutlineIcon/>:<PauseCircleOutlineIcon/>}
                </Button>
            </Box>
        )} */}
        </>
    );
};

export default Homepage;