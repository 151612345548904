import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, IconButton, List, ListItem, Button, ListItemIcon, ListItemText, Divide, Typography, Box, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { ChevronLeft, ChevronRight, CloudUpload, Layers, Search, Home, Language } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DrawIcon from '@mui/icons-material/Draw';
// import { styled } from '@mui/system';
import { handleRaster, handleGeojson, handleDrawUpload } from './eventHandler';
import ToggleLayersSelector from '../sidenav/ToggleLayersSelector';
import { Link } from 'react-router-dom';
import DrawOption from '../sidenav/Draw';
import Tooltip from '@mui/material/Tooltip';
import SaveIcon from '@mui/icons-material/Save';
import { useTheme, styled } from '@mui/material/styles';

const drawerWidth = 360;
const miniSidebarWidth = 60;
const headerHeight = 70;

const url = process.env.PUBLIC_URL;

const Sidebar = styled(Drawer)(({ theme, open }) => ({
  width: open ? drawerWidth : 0,
  flexShrink: 0,
  position: 'absolute',
  left: miniSidebarWidth,
  top: 0,
  height: '100vh',
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth - miniSidebarWidth : 0,
    transition: 'width 0.3s ease-in-out',
    overflowX: 'hidden',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100vh',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}));

const MiniSidebar = styled(Box)(({ theme }) => ({
  width: miniSidebarWidth,
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'fixed',
  left: 0,
  height: '100vh',
  zIndex: 1300,
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const ToggleButton = styled(IconButton)(({ theme, open }) => ({
  position: 'fixed',
  top: 15,
  left: open ? drawerWidth + 10 : miniSidebarWidth + 10,
  transform: 'translateX(-50%)',
  transition: 'left 0.3s ease-in-out',
  zIndex: 1400,
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  width: '100%',
  fontWeight: 'bold',
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(2),
}));

const UploadButton = styled(Button)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(2),
  borderRadius: '28px',
  boxShadow: 'none',
  textTransform: 'none',
  fontWeight: 600,
  '&:hover': {
    boxShadow: theme.shadows[2],
  },
}));

export default function SideNav({
  rasters,
  setRasters,
  vectors,
  setVectors,
  geojsonLayerRefs,
  mapInstance,
  selectedFeatureAttributes,
  projectid,
  setUploading,
  changeStyleData,
  setChangeStyleData,
  handleDownload,
  handleDownloadRaster,
  handleDownloadSelected,
  featureGroupRef,
  inmemory,
  open,
  setOpen,
  selectedFeatures,
  setSelectedFeatures,
  rastersOnTop,
  setRastersOnTop,
  handleSaveClick,
  isSharedView,
}) {
  const theme = useTheme()
  const [activeSection, setActiveSection] = useState('addData');
  const dispatch = useDispatch()

  const fileInputRef = useRef(null);
  const rasterInputRef = useRef(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileClickRaster = () => {
    rasterInputRef.current.click();
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const setActiveLayer = (layer) => {
    setActiveSection(layer)
    setOpen(true)
  }

  return (
    <>
      <MiniSidebar>
        <List sx={{ marginTop: 10 }}>
          <ListItem 
            key="addData" 
            onClick={() => !isSharedView && setActiveLayer('addData')}
            sx={{ cursor: !isSharedView ? 'pointer' : 'not-allowed' }}
          >
            <Tooltip 
              title={!isSharedView ? "Add Data" : "This action is only allowed for logged users"} 
              placement="right"
            >
              <ListItemIcon sx={{ color: !isSharedView ? theme.palette.text.primary : theme.palette.text.disabled }}>
                <PlaylistAddIcon fontSize='large' />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
          <ListItem key="layers" onClick={() => setActiveLayer('layers')} sx={{ cursor: 'pointer' }}>
            <ListItemIcon sx={{ color: theme.palette.text.primary }}>
              <Layers fontSize='large' />
            </ListItemIcon>
          </ListItem>
          <ListItem
            key="draw"
            onClick={() => !inmemory && setActiveLayer('draw')}
            sx={{ cursor: !inmemory ? 'pointer' : 'not-allowed' }}
          >
            <Tooltip 
              title={!inmemory ? "Draw" : "This action is only allowed for logged users"} 
              placement="right" 
              arrow
            >
              <ListItemIcon sx={{ color: !inmemory ? theme.palette.text.primary : theme.palette.text.disabled }}>
                <DrawIcon fontSize="large" />
              </ListItemIcon>
            </Tooltip>
          </ListItem>
        </List>
        <List>
          <ListItem key="save" button onClick={handleSaveClick}>
            <ListItemIcon sx={{ color: theme.palette.text.primary }}>
              <SaveIcon fontSize='large' />
            </ListItemIcon>
          </ListItem>
          <ListItem key="home" component={Link} to="/">
            <ListItemIcon sx={{ color: theme.palette.text.primary }}>
              <Home fontSize='large' />
            </ListItemIcon>
          </ListItem>
        </List>
      </MiniSidebar>

      <Sidebar variant="permanent" open={open}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: headerHeight,
            backgroundColor: theme.palette.background.default,
            transition: 'padding-left 0.3s ease-in-out',
            paddingLeft: 2,
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <img 
            src={url + "/logo-world.svg"} 
            alt="Logo" 
            style={{ 
              width: 50, 
              height: 50, 
              marginRight: 20,
              filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none' 
            }} 
          />
          {open && (
            <SectionHeader variant="h5">
              WebGIS Project
            </SectionHeader>
          )}
        </Box>
        <Divider />
        <List sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          padding: theme.spacing(2)
        }}>
          {activeSection === 'layers' && (
            <ListItem sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <SectionHeader variant="h6">
                Layers
              </SectionHeader>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rastersOnTop}
                    onChange={(e) => setRastersOnTop(e.target.checked)}
                    color="primary"
                  />
                }
                label="Rasters on top"
                sx={{ 
                  alignSelf: 'flex-start', 
                  mt: 1,
                  '& .MuiFormControlLabel-label': {
                    color: theme.palette.text.primary
                  }
                }}
              />
              <ToggleLayersSelector
                rasters={rasters}
                setRasters={setRasters}
                vectors={vectors}
                setVectors={setVectors}
                geojsonLayerRefs={geojsonLayerRefs}
                mapInstance={mapInstance}
                selectedFeatureAttributes={selectedFeatureAttributes}
                changeStyleData={changeStyleData}
                setChangeStyleData={setChangeStyleData}
                handleDownload={handleDownload}
                handleDownloadRaster={handleDownloadRaster}
                handleDownloadSelected={handleDownloadSelected}
                inmemory={inmemory}
                projectId={projectid}
                selectedFeatures={selectedFeatures}
                setSelectedFeatures={setSelectedFeatures}
                rastersOnTop={rastersOnTop}
                setRastersOnTop={setRastersOnTop}
                isSharedView={isSharedView}
              />
            </ListItem>
          )}
          {/* {activeSection === 'search' && (
            <ListItem sx={{ width: '100%' }}>
              <Typography variant="h6" sx={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
                Search
              </Typography>

              <SearchOption
                vectors={vectors}
              />
            </ListItem>
          )} */}
          {activeSection === 'draw' && (
            <ListItem sx={{ 
              width: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center' 
            }}>
              <SectionHeader variant="h6">
                Draw Vector
              </SectionHeader>
              <div id="draw-toolbar-container" style={{ marginTop: '10px' }}>
                <DrawOption
                  map={mapInstance}
                  setVectors={setVectors}
                  projectid={projectid}
                  setUploading={setUploading}
                />
              </div>
            </ListItem>
          )}
          {activeSection === 'addData' && !isSharedView && (
            <ListItem sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ width: '80%', textAlign: 'center' }}>
                <SectionHeader variant="h6">
                  Add Data
                </SectionHeader>
                <input
                    type="file"
                    onChange={(event) => handleGeojson(
                      event,
                      setVectors,
                      mapInstance,
                      dispatch,
                      projectid,
                      setUploading,
                      inmemory
                    )}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept=".geojson, application/geo+json, .zip, .kml, .gpkg, application/vnd.google-earth.kml+xml, application/geopackage+sqlite3"
                  />
                <Tooltip title={"(GeoJSON, KML, GPKG)"} placement="right">
                  <UploadButton
                    variant="contained"
                    color="primary"
                    startIcon={<FileUploadIcon />}
                    onClick={handleFileClick}
                  >
                    Vector
                  </UploadButton>
                </Tooltip>
                {!inmemory && (
                  <>
                    <input
                      type="file"
                      onChange={(event) => handleRaster(
                        event,
                        setRasters,
                        mapInstance,
                        dispatch,
                        projectid,
                        setUploading
                      )}
                      ref={rasterInputRef}
                      style={{ display: 'none' }}
                      accept=".tif"
                    />
                    <Tooltip title={"(GeoTIFF)"} placement="right">
                      <UploadButton
                        variant="contained"
                        color="secondary"
                        startIcon={<FileUploadIcon />}
                        onClick={handleFileClickRaster}
                      >
                        Raster
                      </UploadButton>
                    </Tooltip>
                  </>
                )}
              </Box>
            </ListItem>
          )}
          {/* <ListItem sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box>
              { activeSection === 'addData' && !isSharedView && (
                <>
                  <Typography variant="h6" sx={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
                    Add Data
                  </Typography>
                  <input
                    type="file"
                    onChange={(event) => handleGeojson(
                      event,
                      setVectors,
                      mapInstance,
                      dispatch,
                      projectid,
                      setUploading,
                      inmemory
                    )}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept=".geojson, application/geo+json, .zip, .kml, .gpkg, application/vnd.google-earth.kml+xml, application/geopackage+sqlite3"
                  />
                  <Tooltip title={"(GeoJSON, KML, GPKG)"} placement="right" >
                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        marginBottom: '15px',
                        backgroundColor: '#264653',
                        border: '1px solid #000',
                        borderRadius: '28px',
                        boxShadow: 'none',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#666',
                        }
                      }}
                      startIcon={<FileUploadIcon />}
                      onClick={handleFileClick}
                    >
                      Vector
                    </Button>
                  </Tooltip>
                  {!inmemory?
                  <>
                  <input
                    type="file"
                    onChange={(event) => handleRaster(
                      event,
                      setRasters,
                      mapInstance,
                      dispatch,
                      projectid,
                      setUploading
                    )}
                    ref={rasterInputRef}
                    style={{ display: 'none' }}
                    accept=".tif"
                  />
                  <Tooltip title={"(GeoTIFF)"} placement="right" >
                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        backgroundColor: '#14213D',
                        border: '1px solid #999',
                        borderRadius: '28px',
                        boxShadow: 'none',
                        color: '#FFFFFF',
                        '&:hover': {
                          backgroundColor: '#ccc',
                        }
                      }}
                      startIcon={<FileUploadIcon />}
                      onClick={handleFileClickRaster}
                    >
                      Raster
                    </Button>
                  </Tooltip>
                  </>
                  :null
                  }
                </>
              )}
            </Box>
          </ListItem> */}
        </List>

        <ToggleButton onClick={handleDrawerToggle} open={open}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </ToggleButton>
      </Sidebar>
    </>
  );
}