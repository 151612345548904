import './App.css';
import React, { useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Homepage from './components/HomepageNew';
import Map from './components/Map';
import Login from "./components/Login"
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ResetPassword from './components/ResetPassword';
import Project from './components/Project';
import About from './components/About';
import Contact from './components/Contact';
import NotFound from './components/NotFound';
import PasswordResetConfirm from './components/PasswordResetConfirm';
import EmailConfirmation from './components/EmailConfirm';
import AdmPanel from './components/Adm';
import Subscription from './components/Subscription';
import PricingSpecific from './components/Subscription2';
import { checkAuth } from './features/user';
import SharedView from './components/SharedView';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === 'light'
      ? {
          primary: { main: '#d32f2f' },
          secondary: { main: '#000000' }, 
          background: { 
            default: '#ffffff',
            paper: '#f5f5f5'
          },
          text: { 
            primary: '#000000',
            secondary: '#5f5f5f'
          },
        }
      : {
          primary: { main: '#ff6659' },
          secondary: { main: '#e0e0e0' }, 
          background: { 
            default: '#121212', 
            paper: '#1e1e1e' 
          },
          text: { 
            primary: '#ffffff',
            secondary: '#b0b0b0'
          },
        }),
  },
  // You can add typography and other theme configurations here
});


function App() {
  const [themeMode, setThemeMode] = useState(localStorage.getItem('themeMode') || 'light');
  const theme = createTheme(getDesignTokens(themeMode));

  const [cookies] = useCookies(['access_token', 'refresh_token'])
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem('themeMode', themeMode);
  }, [themeMode]);

  useEffect(() => {
    dispatch(
      checkAuth(cookies.refresh_token)
    ).catch((error) => {
      if (error.message !== '400') {
        console.error('Error:', error);
      }
    });
  }, [dispatch]);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" exact element={<Homepage />} />
          <Route path="/map" exact element={<Map />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={<Dashboard onThemeChange={setThemeMode}/>} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/project" element={<Project />} />
          <Route path="/share/:token" element={<SharedView />} />
          <Route path="/project/:project_id" element={<Project />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/adm-panel" element={<AdmPanel />} />
          <Route path="/password-reset-confirm/:uidb64/:token" element={<PasswordResetConfirm />} />
          <Route path="/email-confirm/:uidb64/:token" element={<EmailConfirmation />} />
  
          {/* Development-only routes */}
          {process.env.NODE_ENV === 'development' && (
            <>
              <Route path="/subscribe/:plan" element={<Subscription />} />
              <Route path="/plans" exact element={<PricingSpecific />} />
            </>
          )}
  
          {/* Catch-all route */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}
//   return (
//     <ThemeProvider theme={theme}>
//       <Router>
//         <Routes>
//           <Route path="/" exact element={<Homepage />} />
//           <Route path="/map" exact element={<Map />} />
//           <Route path="/login" exact element={<Login />} />
//           <Route path="/register" element={<Register />} />
//           <Route path="/dashboard" element={<Dashboard onThemeChange={setThemeMode}/>} />
//           <Route path="/reset" element={<ResetPassword />} />
//           <Route path="/project" element={<Project />} />
//           <Route path="/share/:token" element={<SharedView />} />
//           <Route path="/project/:project_id" element={<Project />} />
//           <Route path="/contact" element={<Contact />} />
//           <Route path="/about" element={<About />} />
//           <Route path="/adm-panel" element={<AdmPanel />} />
//           <Route path="/password-reset-confirm/:uidb64/:token" element={<PasswordResetConfirm />} />
//           <Route path="/email-confirm/:uidb64/:token" element={<EmailConfirmation />} />
//           <Route path="/subscribe/:plan" element={<Subscription />} />
//           <Route path="/plans" exact element={<PricingSpecific/>}/>
//           <Route path="*" element={<NotFound />} />
//         </Routes>
//       </Router>
//     </ThemeProvider>
//   );
// }

export default App;
