import { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Button, CircularProgress, Avatar, Box, Select, FormControl, InputLabel, MenuItem as MuiMenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
// import axios from "axios"
import axiosHttp from "../../../utils/axios"
import Cookies from 'js-cookie';

const formatDataSize = (sizeInBytes) => {
    if (sizeInBytes < 1024) {
        return `${sizeInBytes} B`;
    } else if (sizeInBytes < 1048576) {
        return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    } else if (sizeInBytes < 1073741824) {
        return `${(sizeInBytes / 1048576).toFixed(2)} MB`;
    } else {
        return `${(sizeInBytes / 1073741824).toFixed(2)} GB`;
    }
};


export const getDataUsage = async () => {
    try {
        const accessToken = Cookies.get('access_token');
        // const response = await axios.get(
        const response = axiosHttp.get(
            `${process.env.REACT_APP_API_URL}api/file_manager/get_usage/`,
            {headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });

        return response
    } catch (error) {
        console.error('Error fetching usage:', error);
    }
}


export const ProfileUserInformation = ({user,profile}) => {
    // const { user, loading } = useSelector(state => state.user);
    const [dataUsage,setDataUsage] = useState({})
    const [dataTransfer,setDataTransfer] = useState(0)
    const { t } = useTranslation();
    // const profile = user && user.profile;

    const getUsage = async ()=>{
        const response = await getDataUsage()
        if (response.status===200){
            const usage = response.data
            setDataUsage(usage)
            
        } else {
            console.log("ERROR LOADING USAGE:",response)
        }
        

    }   
    useEffect(()=>{
        getUsage() 
    },[])

    useEffect(()=>{
        // const dataTransfer = 
            // Number(dataUsage["raster_download"]) + 
            // Number(dataUsage["raster_uploads"]) +  
            // Number(dataUsage["vector_download"]) + 
            // Number(dataUsage["vector_uploads"])
            console.log(dataUsage)
        setDataTransfer(dataUsage["total"])//dataTransfer)
    },[dataUsage])
    
    return (
        <Card sx={{ maxWidth: 500, margin: 'auto', padding: 2 }}>
            <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar
                        sx={{ width: 100, height: 100, mb: 2 }}
                        src={profile.profile_picture || '/default-profile.png'}
                    />
                    <Typography variant="h6">{user.username}</Typography>
                    <Typography variant="subtitle1" color="textSecondary">{user.email}</Typography>
                    <Typography variant="body1" color="textSecondary" mt={2}>
                        {t('data_size_raster')}: {formatDataSize(profile.total_raster_usage)}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" mt={2}>
                        {t('data_size_vector')}: {formatDataSize(profile.total_vector_usage)}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" mt={2}>
                        {t('data_transfer')}: {formatDataSize(dataTransfer)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mt={2}>
                        {profile.bio || t('no_bio')}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    )
}