import React, { useState, useEffect } from 'react';
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { zoomToLayerRaster } from './RasterFunctions';
import { zoomToLayer } from './VectorFunctions';
import { List } from '@mui/material';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import SortableItem from './SortableItem';
import { useTheme } from '@mui/material/styles';

const ToggleLayersSelector = ({
  rasters,
  setRasters,
  vectors,
  setVectors,
  geojsonLayerRefs,
  mapInstance,
  selectedFeatureAttributes,
  changeStyleData,
  setChangeStyleData,
  handleDownload,
  handleDownloadRaster,
  handleDownloadSelected,
  inmemory = false,
  projectId,
  selectedFeatures,
  setSelectedFeatures,
  rastersOnTop,
  setRastersOnTop,
  isSharedView,
}) => {
  const theme = useTheme();
  const [combinedList, setCombinedList] = useState([]);
  

  useEffect(() => {
    const combined = [
      ...vectors.map((vector) => ({
        id: `vector-${vector.data.properties.id}`,
        type: 'vector',
        item: vector,
      })),
      ...rasters.map((raster) => ({
        id: `raster-${raster.data.id}`,
        type: 'raster',
        item: raster,
      })),
    ];
    setCombinedList(combined);
  }, [vectors, rasters]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })

  );

  const updateStyle = (polygonId, styleKey, value) => {
    setVectors((prevVectors) =>
      prevVectors.map((geojson) => {
        if (geojson.data.properties.id === polygonId) {
          const updatedGeoms = geojson.data.features.map((geom) => ({
            ...geom,
            style: {
              ...geom.style,
              [styleKey]: value,
            },
          }));
          return {
            ...geojson,
            data: {
              ...geojson.data,
              features: updatedGeoms,
            },
          };
        }
        return geojson;
      })
    );
  };

  const updateStyleCat = (polygonId, styleKey, value, featureId = null) => {
    setVectors((prevVectors) =>
      prevVectors.map((geojson) => {
        if (geojson.data.properties.id === polygonId) {
          const updatedGeoms = geojson.data.features.map((geom) => {
            if (featureId && geom.id !== Number(featureId)) return geom;
            return {
              ...geom,
              style: {
                ...geom.style,
                [styleKey]: value,
              },
            };
          });
          return {
            ...geojson,
            data: {
              ...geojson.data,
              features: updatedGeoms,
            },
          };
        }
        return geojson;
      })
    );
  };
/////////////////////////////////////////////////////////////////////////////////////////////
  const handleDragEnd = async (event) => {
    const { active, over } = event;
    // const token = Cookies.get('access_token')
    if (!over) return;

    if (active.id === over.id) return;

    const oldIndex = combinedList.findIndex((item) => item.id === active.id);
    const newIndex = combinedList.findIndex((item) => item.id === over.id);

    const reordered = arrayMove(combinedList, oldIndex, newIndex);
    setCombinedList(reordered);

    const reversedList = [...reordered].reverse();
    
    //TODO: Save in the backend.
    reversedList.forEach((item, i) => {
      const rasterPosition = rastersOnTop ? 200 : 100
      const zIndex = rasterPosition + i;
      if (item.type === "raster" && item.item.layerRef) {
        item.item.layerRef.setZIndex(zIndex);

      } else if (item.type === "vector" && geojsonLayerRefs.current[item.item.data.properties.id]) {
        const vectorLayer = geojsonLayerRefs.current[item.item.data.properties.id];
        vectorLayer.bringToFront();
      }
    });
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        items={combinedList.map((item) => item.id)}
        strategy={verticalListSortingStrategy}
      >
        <List sx={{ 
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.primary
        }}>
          {combinedList.map((layer) => (
            <SortableItem
              key={layer.id}
              id={layer.id}
              data={{
                type: layer.type,
                item: layer.item,
                parentVectors: vectors,
                parentRasters: rasters,
                setVectors,
                setRasters,
                zoomToLayer,
                zoomToLayerRaster: zoomToLayerRaster,
                updateStyle,
                updateStyleCat,
                selectedFeatureAttributes,
                inmemory,
                changeStyleData,
                setChangeStyleData,
                handleDownload,
                handleDownloadRaster,
                handleDownloadSelected,
                geojsonLayerRefs,
                mapInstance,
                selectedFeatures,
                setSelectedFeatures,
                isSharedView
              }}
            />
          ))}
        </List>
      </SortableContext>
    </DndContext>
  );
};

export default ToggleLayersSelector;



/////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////       DO NOT DELETE       ///////////////////////////////////////////////



// mapInstance.removeLayer(item.item.layerRef);
// mapInstance.addLayer(item.item.layerRef);

    // const newVectors = reordered
    //   .filter((item) => item.type === 'vector')
    //   .map((item) => item.item);
    // const newRasters = reordered
    //   .filter((item) => item.type === 'raster')
    //   .map((item) => item.item);


    // setVectors(newVectors);
    // setRasters(newRasters);

    // const rasterOrder = newRasters.map((raster) => raster.data.id); // Ajuste conforme o campo de ID
    // const vectorOrder = newVectors.map((vector) =>  vector.data.properties.id); // Ajuste conforme o campo de ID

    // console.log("Raster Order:", rasterOrder);
    // console.log("Vector Order:", vectorOrder);

  //   const response = await axios.patch(`${API_URL}api/main/project/${projectId}/update-order/`, {
  //     raster_order: rasterOrder,
  //     vector_order: vectorOrder,
  //   }, {
  //     headers: {
  //       Accept: 'application/json',
  //       Authorization: `Bearer ${token}`,
  //     }
  //   });
  //   if (response.status === 200) {
  //     console.log('Ordem de camadas salva com sucesso');
  //   } else {
  //     console.error('Erro ao salvar a ordem de camadas', response);
  //   };






  /////////////////////////////////////////////////////////////////////////////////////////////////////////////