import axios from "axios";
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

const axiosHttp = axios.create({
    baseURL: `${API_URL}`,
});

axiosHttp.interceptors.request.use(
    async (config) => {
        const token = Cookies.get('access_token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosHttp.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;

            const refreshToken = Cookies.get('refresh_token');
            if (refreshToken) {
                try {
                    const response = await axios.post(
                        `${API_URL}api/users/token/refresh/`, 
                        {
                            refresh: refreshToken 
                        },
                        { 
                            headers: {
                                Accept: 'application/json',
                                'Content-Type': 'application/json',
                            }
                        }
                    );
                    const newAccessToken = response.data.access;
                    Cookies.set('access_token', newAccessToken, { expires: 30 });

                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return axiosHttp(originalRequest);
                } catch (refreshError) {
                    console.log("ERROR 1",refreshError)
                    Cookies.remove('access_token');
                    Cookies.remove('refresh_token');
                }
            } else {
                console.log("ERROR 2")
                Cookies.remove('access_token');
                Cookies.remove('refresh_token');
                // alert("Your session has expired. Please log in again.");
                // window.location.href = "/"; 
            }
        }
        return Promise.reject(error);
    }
);

export default axiosHttp;
