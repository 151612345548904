
import parse from 'wellknown';


export const parseVector = (vector) => {
    console.log("VECTOR", vector);
    const parsed = vector.map((data) => {
      const dataInfo = {
        type: 'FeatureCollection',
        features: [],
        properties: {
          id: data.id,
          visible: false,
          name: data.name,
          format: data.format_name,
          order: data.order,
          // style: data.style,
          // styleType:data.style_type
          // attributes: item.attributes,
        },
      };
      // console.log(data)
      data.geoms.map((geojson) => {
        const parts = geojson.geometry.split(';');
        const geom = parts.length > 1 ? parse(parts[1]) : null;
  
        const feature = {
          type: 'Feature',
          id: geojson.id,
          geometry: geom,
          properties: {
            attributes: geojson.attributes,
          },
          style: geojson.style,
        }
        dataInfo.features.push(feature)
  
      })
      return dataInfo
    })
  
    return parsed
  }
  

  export const parseVectorInMemory = (vector, existingIds = new Set()) => {

    let uniqueIdCounter = 0;

    const parsed = Object.keys(vector).map((key) => {
        const data = vector[key].data;
        const dataInfo = {
            data: {
                type: 'FeatureCollection',
                features: data.features.map((feature) => {
                    let featureId = feature.id || `inmemory-${uniqueIdCounter++}`;
                    while (existingIds.has(featureId)) {
                        featureId = `inmemory-${uniqueIdCounter++}`;
                    }
                    existingIds.add(featureId);

                    return {
                        type: 'Feature',
                        id: featureId,
                        geometry: feature.geometry,
                        properties: {
                            attributes: feature.properties,
                        },
                        style: feature.style || {},
                    };
                }),
                properties: {
                    id: data.id || `inmemory-${uniqueIdCounter++}`,
                    visible: data.visible || false,
                    name: data.name || "Unnamed Vector",
                    format: data.format || "geojson",
                    order: data.order || 0,
                },
            },
            visible: data.visible || false,
        };

        while (existingIds.has(dataInfo.data.properties.id)) {
            dataInfo.data.properties.id = `inmemory-${uniqueIdCounter++}`;
        }
        existingIds.add(dataInfo.data.properties.id);

        return dataInfo;
    });
    return parsed;
};
