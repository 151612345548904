import React, { useState } from 'react';
// import axios from 'axios';
import axiosHttp from '../../../utils/axios';
import Cookies from 'js-cookie';
import {
    Box,
    Typography,
    Slider,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    InputAdornment,
    IconButton,
} from '@mui/material';
import ColorLensIcon from '@mui/icons-material/ColorLens';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/'

const GlobalStyle = ({ geojson, updateStyle, setIsModalOpen }) => {
    const [color, setColor] = useState(geojson.data.properties.style?.fillColor || '#000000');
    const [lineColor, setLineColor] = useState(geojson.data.properties.style?.color || '#000000');
    const [width, setWidth] = useState(geojson.data.properties.style?.weight || 2);
    const [opacity, setOpacity] = useState(geojson.data.properties.style?.fillOpacity || 1.0);
    const [radius, setRadius] = useState(geojson.data.properties.style?.radius || 8);

    let isPoint = false;
    let isLine = false;
    let isPolygon = false;

    if (geojson.data.type === 'FeatureCollection') {
        isPoint = geojson.data.features.some(
            (feature) => feature.geometry?.type === 'Point' || feature.geometry?.type === 'MultiPoint'
        );
        isLine = geojson.data.features.some(
            (feature) => feature.geometry?.type === 'LineString' || feature.geometry?.type === 'MultiLineString'
        );
        isPolygon = geojson.data.features.some(
            (feature) => feature.geometry?.type === 'Polygon' || feature.geometry?.type === 'MultiPolygon'
        );
    } else {
        const geometryType = geojson.data.geometry?.type;
        isPoint = geometryType === 'Point' || geometryType === 'MultiPoint';
        isLine = geometryType === 'LineString' || geometryType === 'MultiLineString';
        isPolygon = geometryType === 'Polygon' || geometryType === 'MultiPolygon';
    }

    const handleSaveStyle = async () => {
        try {
            const vectorId = geojson.data.properties.id;
            const token = Cookies.get('access_token');

            const updatedStyle = {
                color: lineColor,
                weight: width,
                fillColor: color,
                fillOpacity: opacity,
                radius: radius,
            };

            const response = await axiosHttp.post(
                `${API_URL}api/main/vectors/${vectorId}/save-style/`,
                { style: updatedStyle },
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                geojson.data.features.forEach((feature) => {
                    const featureId = feature.id;
                    updateStyle(geojson.data.properties.id, 'fillColor', color, featureId);
                    updateStyle(geojson.data.properties.id, 'color', lineColor, featureId);
                    updateStyle(geojson.data.properties.id, 'weight', width, featureId);
                    updateStyle(geojson.data.properties.id, 'fillOpacity', opacity, featureId);
                    updateStyle(geojson.data.properties.id, 'radius', radius, featureId);
                });
                setIsModalOpen(false)
            } else {
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            console.error('Error saving style:', error);
        }
    };

    const getSanitizedColorId = (color) => color.replace('#', '');

    const renderColorPicker = (value, setValue) => {
        const sanitizedId = getSanitizedColorId(value);
        return (
            <TextField
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                size="small"
                variant="outlined"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <IconButton
                                style={{
                                    backgroundColor: value,
                                    width: '24px',
                                    height: '24px',
                                    border: '1px solid #ddd',
                                }}
                                onClick={() => document.querySelector(`#color-input-${sanitizedId}`).click()}
                            >
                                <ColorLensIcon 
                                    style={{ color: '#fff' }} 
                                />
                            </IconButton>
                            <input
                                id={`color-input-${sanitizedId}`}
                                type="color"
                                style={{ display: 'none' }}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                            />
                        </InputAdornment>
                    ),
                }}
            />
        );
    };

    return (
        <Box sx={{ padding: 2, border: '1px solid #ddd', borderRadius: 2, backgroundColor: '#f9f9f9' }}>
            <Typography variant="h6" gutterBottom>
                Style Editor
            </Typography>
            <Table>
                <TableBody>
                    {isPoint || isPolygon ? (
                        <TableRow>
                            <TableCell>Fill Color</TableCell>
                            <TableCell align="right">{renderColorPicker(color, setColor)}</TableCell>
                        </TableRow>
                    ) : null}
                    {isLine || isPolygon ? (
                        <TableRow>
                            <TableCell>Line Color</TableCell>
                            <TableCell align="right">{renderColorPicker(lineColor, setLineColor)}</TableCell>
                        </TableRow>
                    ) : null}
                    {isLine ? (
                        <TableRow>
                            <TableCell>Line Size</TableCell>
                            <TableCell align="right">
                                <Slider
                                    value={width}
                                    onChange={(e, value) => setWidth(value)}
                                    min={0}
                                    max={10}
                                    step={1}
                                    valueLabelDisplay="auto"
                                />
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {isPoint || isPolygon ? (
                        <TableRow>
                            <TableCell>Opacity</TableCell>
                            <TableCell align="right">
                                <Slider
                                    value={opacity}
                                    onChange={(e, value) => setOpacity(value)}
                                    min={0}
                                    max={1}
                                    step={0.1}
                                    valueLabelDisplay="auto"
                                />
                            </TableCell>
                        </TableRow>
                    ) : null}
                    {isPoint ? (
                        <TableRow>
                            <TableCell>Radius</TableCell>
                            <TableCell align="right">
                                <Slider
                                    value={radius}
                                    onChange={(e, value) => setRadius(value)}
                                    min={0}
                                    max={20}
                                    step={1}
                                    valueLabelDisplay="auto"
                                />
                            </TableCell>
                        </TableRow>
                    ) : null}
                    <TableRow>
                        <TableCell colSpan={2} align="right">
                            <Button 
                                variant="contained" 
                                color="primary" 
                                onClick={handleSaveStyle}>
                                Save Style
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Box>
    );
};

export default GlobalStyle;





// const GlobalStyle = ({ geojson, updateStyle }) => {
//     const [color, setColor] = useState(geojson.data.properties.style?.fillColor || '#000000');
//     const [lineColor, setLineColor] = useState(geojson.data.properties.style?.color || '#000000');
//     const [width, setWidth] = useState(geojson.data.properties.style?.weight || 2);
//     const [opacity, setOpacity] = useState(geojson.data.properties.style?.fillOpacity || 1.0);
//     const [radius, setRadius] = useState(geojson.data.properties.style?.radius || 8);

//     const handleColorChange = (e) => setColor(e.target.value);
//     const handleLineColorChange = (e) => setLineColor(e.target.value);
//     const handleWidthChange = (e) => setWidth(e.target.value);
//     const handleOpacityChange = (e) => setOpacity(e.target.value);
//     const handleRadiusChange = (e) => setRadius(e.target.value);

//     let isPoint = false;
//     let isLine = false;
//     let isPolygon = false;

//     if (geojson.data.type === 'FeatureCollection') {
//         isPoint = geojson.data.features.some(feature => feature.geometry && (feature.geometry.type === "Point" || feature.geometry.type === "MultiPoint"));
//         isLine = geojson.data.features.some(feature => feature.geometry && (feature.geometry.type === "LineString" || feature.geometry.type === "MultiLineString"));
//         isPolygon = geojson.data.features.some(feature => feature.geometry && (feature.geometry.type === "Polygon" || feature.geometry.type === "MultiPolygon"));
//     } else {
//         isPoint = geojson.data.geometry.type === "Point" || geojson.data.geometry.type === "MultiPoint";
//         isLine = geojson.data.geometry.type === "LineString" || geojson.data.geometry.type === "MultiLineString";
//         isPolygon = geojson.data.geometry.type === "Polygon" || geojson.data.geometry.type === "MultiPolygon";
//     }

//     const data = [
//         { name: "Fill Color", class: "input-color-style", type: "color", value: color, onchange: handleColorChange, show: isPoint || isPolygon },
//         { name: "Line Color", class: "input-color-style", type: "color", value: lineColor, onchange: handleLineColorChange, show: isLine || isPolygon },
//         { name: "Line Size", class: "sidenav-range-style", type: "range", min: 0, max: 10, step: 1, value: width, onchange: handleWidthChange, show: isLine },
//         { name: "Opacity", class: "sidenav-range-style", type: "range", min: 0, max: 1, step: 0.1, value: opacity, onchange: handleOpacityChange, show: isPoint || isPolygon },
//         { name: "Radius", class: "sidenav-range-style", type: "range", min: 0, max: 20, step: 1, value: radius, onchange: handleRadiusChange, show: isPoint }
//     ];

//     const handleSaveStyle = async (geojson) => {
//         try {
//             const style = geojson.data.properties.style || {};
//             const vectorId = geojson.data.properties.id;
//             const token = Cookies.get('access_token');

//             const updatedStyle = {
//                 color: lineColor,
//                 weight: width,
//                 fillColor: color,
//                 fillOpacity: opacity,
//                 radius: radius
//             };

//             const response = await axios.post(
//                 `${API_URL}api/main/vectors/${vectorId}/save-style/`,
//                 { style: updatedStyle },
//                 {
//                     headers: {
//                         Accept: 'application/json',
//                         Authorization: `Bearer ${token}`
//                     }
//                 }
//             );

//             if (response.status === 200) {
//                 geojson.data.features.forEach(feature => {
//                     const featureId = feature.id;
//                     updateStyle(geojson.data.properties.id, "fillColor", color, featureId);
//                     updateStyle(geojson.data.properties.id, "color", lineColor, featureId);
//                     updateStyle(geojson.data.properties.id, "weight", width, featureId);
//                     updateStyle(geojson.data.properties.id, "fillOpacity", opacity, featureId);
//                     updateStyle(geojson.data.properties.id, "radius", radius, featureId);
//                 });
//             } else {
//                 console.error('Unexpected response:', response);
//             }
//         } catch (error) {
//             console.error('Error saving style:', error);
//         }
//     };

//     const saveStyle = (
//         <tr>
//             <td><span>Save style</span></td>
//             <td className='alnright'>
//                 <a onClick={() => handleSaveStyle(geojson)} className='btn blue'>
//                     <i className='material-icons'>save</i>
//                 </a>
//             </td>
//         </tr>
//     );

//     return (
//         <table>
//             <tbody>
//                 {data.map((d, index) =>
//                     d.show ? (
//                         <tr key={index}>
//                             <td><span>{d.name}</span></td>
//                             <td className='alnright'>
//                                 <input
//                                     className={d.class}
//                                     type={d.type}
//                                     min={d.min}
//                                     max={d.max}
//                                     step={d.step}
//                                     value={d.value}
//                                     onChange={d.onchange}
//                                 />
//                             </td>
//                         </tr>
//                     ) : null
//                 )}
//                 {saveStyle}
//             </tbody>
//         </table>
//     );
// };
