
import { useState } from 'react';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    Typography,
    Slider,
    Button,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from "@mui/material";
// import axios from 'axios';
import axiosHttp from '../../../utils/axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/';

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const CategorizedStyle = ({ geojson, updateStyle, setIsModalOpen }) => {
    const [column, setColumn] = useState(null);
    const [uniqueValues, setUniqueValues] = useState([]);
    const [colors, setColors] = useState({});
    const [weight, setWeight] = useState(2);
    const [fillOpacity, setFillOpacity] = useState(1.0);

    const handleSaveCategorizedStyle = async () => {
        try {
            const vectorId = geojson.data.properties.id;
            const token = Cookies.get('access_token');

            const categorizedStyles = geojson.data.features.reduce((acc, feature) => {
                const attributeValue = feature.properties.attributes[column];
                const style = { ...feature.style };

                switch (feature.geometry.type) {
                    case 'Point':
                    case 'MultiPoint':
                        acc[feature.id] = {
                            ...style,
                            radius: weight,
                            fillColor: colors[attributeValue],
                            color: 'black',
                            weight: 2,
                            opacity: 1,
                            fillOpacity,
                        };
                        break;
                    case 'LineString':
                    case 'MultiLineString':
                        acc[feature.id] = {
                            ...style,
                            color: colors[attributeValue],
                            weight,
                        };
                        break;
                    case 'Polygon':
                    case 'MultiPolygon':
                        acc[feature.id] = {
                            ...style,
                            fillColor: colors[attributeValue],
                            color: 'black',
                            weight,
                            fillOpacity,
                        };
                        break;
                    default:
                        console.warn(`Unsupported geometry type: ${feature.geometry.type}`);
                }
                return acc;
            }, {});

            const response = await axiosHttp.post(
                `${API_URL}api/main/vectors/${vectorId}/save-style-cat/`,
                { categorized_styles: categorizedStyles },
                {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${token}`,
                    }
                }
            );

            if (response.status === 200) {
                Object.keys(categorizedStyles).forEach(featureId => {
                    const style = categorizedStyles[featureId];
                    if (style.fillColor) updateStyle(geojson.data.properties.id, "fillColor", style.fillColor, featureId);
                    if (style.color) updateStyle(geojson.data.properties.id, "color", style.color, featureId);
                    if (style.weight) updateStyle(geojson.data.properties.id, "weight", style.weight, featureId);
                    if (style.fillOpacity) updateStyle(geojson.data.properties.id, "fillOpacity", style.fillOpacity, featureId);
                    if (style.radius) updateStyle(geojson.data.properties.id, "radius", style.radius, featureId);
                    if (style.opacity) updateStyle(geojson.data.properties.id, "opacity", style.opacity, featureId);
                });
                setIsModalOpen(false);
            } else {
                console.error('Unexpected response:', response);
            }
        } catch (error) {
            console.error('Error saving categorized style:', error);
        }
    };

    const columns = geojson?.data?.features?.[0]?.properties?.attributes
        ? Object.keys(geojson.data.features[0].properties.attributes)
        : [];

    const handleChange = (event) => {
        const selectedColumn = event.target.value;
        setColumn(selectedColumn);

        const values = geojson.data.features.map(
            (feature) => feature.properties.attributes[selectedColumn]
        );

        const unique = [...new Set(values)];
        setUniqueValues(unique);

        const initialColors = unique.reduce((acc, value) => {
            acc[value] = getRandomColor();
            return acc;
        }, {});
        setColors(initialColors);
    };

    const handleColorChange = (value, color) => {
        setColors((prevColors) => ({
            ...prevColors,
            [value]: color
        }));
    };

    const handleWeightChange = (event, newValue) => {
        setWeight(newValue);
    };

    const handleFillOpacityChange = (event, newValue) => {
        setFillOpacity(newValue);
    };

    return (
        <Box sx={{ p: 3 }}>
            <FormControl fullWidth variant="outlined" sx={{ mb: 3 }}>
                <InputLabel id="column-select-label">Column</InputLabel>
                <Select
                    labelId="column-select-label"
                    id="column-select"
                    value={column}
                    label="Column"
                    onChange={handleChange}
                >
                    {columns.map((col) => (
                        <MenuItem key={col} value={col}>
                            {col}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {column && uniqueValues.length > 0 && (
                <>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Assign Colors
                    </Typography>
                    <List>
                        {uniqueValues.map((value) => (
                            <ListItem key={value} disablePadding>
                                <ListItemIcon>
                                    <input
                                        type="color"
                                        value={colors[value]}
                                        onChange={(e) => handleColorChange(value, e.target.value)}
                                        style={{ border: 'none', cursor: 'pointer' }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={value} />
                            </ListItem>
                        ))}
                    </List>

                    <Box sx={{ mt: 3 }}>
                        <Typography gutterBottom>Size/Radius</Typography>
                        <Slider
                            value={weight}
                            onChange={handleWeightChange}
                            min={0}
                            max={10}
                            step={1}
                            valueLabelDisplay="auto"
                        />
                    </Box>

                    <Box sx={{ mt: 3 }}>
                        <Typography gutterBottom>Fill Opacity</Typography>
                        <Slider
                            value={fillOpacity}
                            onChange={handleFillOpacityChange}
                            min={0}
                            max={1}
                            step={0.1}
                            valueLabelDisplay="auto"
                        />
                    </Box>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveCategorizedStyle}
                        sx={{ mt: 3 }}
                    >
                        Save
                    </Button>
                </>
            )}
        </Box>
    );
};

export default CategorizedStyle;

// import { useState, useEffect } from 'react';
// import {
//     FormControl,
//     InputLabel,
//     Select,
//     MenuItem,
// } from "@mui/material"
// import axios from 'axios';
// import Cookies from 'js-cookie';

// const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/'

// const getRandomColor = () => {
//     const letters = '0123456789ABCDEF';
//     let color = '#';
//     for (let i = 0; i < 6; i++) {
//         color += letters[Math.floor(Math.random() * 16)];
//     }
//     return color;
// };

// const CategorizedStyle = ({ geojson, updateStyle,setIsModalOpen }) => {
//     const [column, setColumn] = useState(null);
//     const [uniqueValues, setUniqueValues] = useState([]);
//     const [colors, setColors] = useState({});
//     const [weight, setWeight] = useState(2);
//     const [fillOpacity, setFillOpacity] = useState(1.0);

//     const handleSaveCategorizedStyle = async () => {
//         try {
//             const vectorId = geojson.data.properties.id;
//             const token = Cookies.get('access_token');

//             const categorizedStyles = geojson.data.features.reduce((acc, feature) => {
//                 const attributeValue = feature.properties.attributes[column];
//                 const style = { ...feature.style };

//                 switch (feature.geometry.type) {
//                     case 'Point':
//                     case 'MultiPoint':
//                         acc[feature.id] = {
//                             ...style,
//                             radius: weight,
//                             fillColor: colors[attributeValue],
//                             color: 'black',
//                             weight: 2,
//                             opacity: 1,
//                             fillOpacity,
//                         };
//                         break;
//                     case 'LineString':
//                     case 'MultiLineString':
//                         acc[feature.id] = {
//                             ...style,
//                             color: colors[attributeValue],
//                             weight,
//                         };
//                         break;
//                     case 'Polygon':
//                     case 'MultiPolygon':
//                         acc[feature.id] = {
//                             ...style,
//                             fillColor: colors[attributeValue],
//                             color: 'black',
//                             weight,
//                             fillOpacity,
//                         };
//                         break;
//                     default:
//                         console.warn(`Unsupported geometry type: ${feature.geometry.type}`);
//                 }
//                 return acc;
//             }, {});


//             const response = await axios.post(
//                 `${API_URL}api/main/vectors/${vectorId}/save-style-cat/`,
//                 { categorized_styles: categorizedStyles },
//                 {
//                     headers: {
//                         Accept: 'application/json',
//                         Authorization: `Bearer ${token}`,
//                     }
//                 }
//             );

//             if (response.status === 200) {
//                 Object.keys(categorizedStyles).forEach(featureId => {
//                     const style = categorizedStyles[featureId];
//                     if (style.fillColor) updateStyle(geojson.data.properties.id, "fillColor", style.fillColor, featureId);
//                     if (style.color) updateStyle(geojson.data.properties.id, "color", style.color, featureId);
//                     if (style.weight) updateStyle(geojson.data.properties.id, "weight", style.weight, featureId);
//                     if (style.fillOpacity) updateStyle(geojson.data.properties.id, "fillOpacity", style.fillOpacity, featureId);
//                     if (style.radius) updateStyle(geojson.data.properties.id, "radius", style.radius, featureId);
//                     if (style.opacity) updateStyle(geojson.data.properties.id, "opacity", style.opacity, featureId);
//                 });
//                 setIsModalOpen(false)
//             } else {
//                 console.error('Unexpected response:', response);
//             }
//         } catch (error) {
//             console.error('Error saving categorized style:', error);
//         }
//     };

//     const columns = geojson?.data?.features?.[0]?.properties?.attributes
//         ? Object.keys(geojson.data.features[0].properties.attributes)
//         : [];

//     const handleChange = (event) => {
//         const selectedColumn = event.target.value;
//         setColumn(selectedColumn);

//         const values = geojson.data.features.map(
//             (feature) => feature.properties.attributes[selectedColumn]
//         );
//         console.log("VALUES", values);

//         const unique = [...new Set(values)];
//         setUniqueValues(unique);

//         const initialColors = unique.reduce((acc, value) => {
//             acc[value] = getRandomColor();
//             return acc;
//         }, {});
//         setColors(initialColors);
//     };

//     const handleColorChange = (value, color) => {
//         setColors((prevColors) => ({
//             ...prevColors,
//             [value]: color
//         }));
//     };

//     const handleWeightChange = (event) => {
//         setWeight(Number(event.target.value));
//     };

//     const handleFillOpacityChange = (event) => {
//         setFillOpacity(Number(event.target.value));
//     };
//     return (
//         <>
//             <FormControl fullWidth>
//                 <InputLabel id="demo-simple-select-label">Column</InputLabel>
//                 <Select
//                     labelId="demo-simple-select-label"
//                     id="demo-simple-select"
//                     value={column}
//                     label="Column"
//                     onChange={handleChange}
//                 >
//                     {columns.map((col) => (
//                         <MenuItem key={col} value={col}>
//                             {col}
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>

//             {column && uniqueValues.length > 0 && (
//                 <>
//                     <ul style={{ marginTop: '16px', listStyle: 'none', padding: 0 }}>
//                         {uniqueValues.map((value) => (
//                             <li key={value} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
//                                 <input
//                                     type="color"
//                                     value={colors[value]}
//                                     onChange={(e) => handleColorChange(value, e.target.value)}
//                                     style={{ marginRight: '8px' }}
//                                 />
//                                 <span>{value}</span>
//                             </li>
//                         ))}
//                     </ul>

//                     {['Point', 'MultiPoint', 'Polygon', 'MultiPolygon'].includes(geojson.data.features[0].geometry.type) && (
//                         <>
//                             <div style={{ marginTop: '16px' }}>
//                                 <label>
//                                     Radius:
//                                     <input
//                                         type="range"
//                                         value={weight}
//                                         onChange={handleWeightChange}
//                                         min="0"
//                                         max="10"
//                                         step="1"
//                                         style={{ marginLeft: '8px' }}
//                                     />
//                                 </label>
//                             </div>
//                             <div style={{ marginTop: '16px' }}>
//                                 <label>
//                                     Opacity:
//                                     <input
//                                         type="range"
//                                         value={fillOpacity}
//                                         onChange={handleFillOpacityChange}
//                                         min="0"
//                                         max="1"
//                                         step="0.1"
//                                         style={{ marginLeft: '8px' }}
//                                     />
//                                 </label>
//                             </div>
//                         </>
//                     )}

//                     {['LineString', 'MultiLineString'].includes(geojson.data.features[0].geometry.type) && (
//                         <div style={{ marginTop: '16px' }}>
//                             <label>
//                                 Line size:
//                                 <input
//                                     type="range"
//                                     value={weight}
//                                     onChange={handleWeightChange}
//                                     min="0"
//                                     max="10"
//                                     step="1"
//                                     style={{ marginLeft: '8px' }}
//                                 />
//                             </label>
//                         </div>
//                     )}

//                     <button onClick={handleSaveCategorizedStyle}>
//                         Save Categorized Style
//                     </button>
//                 </>
//             )}
//         </>
//     );
// }
// export default CategorizedStyle;
