import React, { useState, useEffect } from 'react';
import { 
    getProjects, 
    handleNewProject, 
    handleDeleteOption, 
    handleChooseOption, 
    setData, 
    setSharedData 
} from './utils/ProjectFunctions';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { MapComponent } from './utils/MapComponent';
import "../styles/Project.css";
import Navbar from './include/Navbar';
import { loadingPage } from './utils/Loading';
import axiosHttp from '../utils/axios';

// Import MUI components
import { Box, Button, Card, CardContent, CardMedia, Typography, Grid, IconButton, Input, TextField } from '@mui/material';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import { useTheme, styled } from '@mui/material/styles';

const API_URL = process.env.REACT_APP_API_URL;



const ProjectCard = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: theme.shadows[4]
    },
}));

const ProjectGrid = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(4),
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
}));

const NewProjectContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
}));


function Project() {
    const theme = useTheme();
    const navigate = useNavigate();
    const { isAuthenticated, user, loading } = useSelector(state => state.user);
    const [project, setProject] = useState(null);
    const [projects, setProjects] = useState([]);
    const [projectTextInput, setProjectTextInput] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [rasters, setRasters] = useState([]);
    const [vectors, setVectors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSharedView,setIsSharedView] = useState(false);
    const { project_id, token } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (token) {
                    const response = await axiosHttp.get(
                    // const response = await axios.get(
                        `${API_URL}/api/main/share/${token}/`
                    );
                    if (!project) {
                        await setSharedData(response.data, setProject, setRasters, setVectors);
                        setIsSharedView(true)
                    }
                } else if (project_id && project === null) {
                    setData(setProject, setRasters, project_id, projects, navigate, setVectors);
                } else if (projects.length === 0) {
                    await getProjects(setProjects);
                }
            } catch (error) {
                console.error('Error loading shared project:', error);
            }
            setIsLoading(false);
        };

        if (!project) {
            setIsLoading(true);
            fetchData();
            setIsLoading(false);
        }
    }, [project_id, token, project]);

    // if (!isAuthenticated && !loading && user === null && !token) {
    //     return <Navigate to='/login' />;
    // }

    const chooseProject = (
        <>
            <Navbar />
            <ProjectGrid container spacing={4}>
                {projects.map((project, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                        <ProjectCard>
                            <CardMedia
                                component="img"
                                height="160"
                                image={project.thumbnail || `${process.env.PUBLIC_URL}/thumbnail_map.png`}
                                alt={`${project.name} thumbnail`}
                                sx={{
                                    objectFit: 'cover',
                                    bgcolor: theme.palette.mode === 'dark' ? '#1e1e1e' : '#f5f5f5'
                                }}
                            />
                            <CardContent>
                                <Typography
                                    variant="h6"
                                    component="div"
                                    color="text.primary"
                                    sx={{ fontWeight: 600 }}
                                >
                                    {project.name}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="text.secondary"
                                    sx={{ mt: 1 }}
                                >
                                    Last updated: {project.updated_at}
                                </Typography>
                            </CardContent>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                px: 2,
                                py: 1,
                                borderTop: `1px solid ${theme.palette.divider}`
                            }}>
                                <IconButton
                                    color="primary"
                                    onClick={() => handleChooseOption(project.id, navigate)}
                                >
                                    <OpenInBrowserIcon />
                                </IconButton>
                                <IconButton
                                    color="error"
                                    onClick={() => handleDeleteOption(project.id, setProjects)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        </ProjectCard>
                    </Grid>
                ))}
            </ProjectGrid>
            <Box sx={{
                textAlign: 'center',
                py: 4,
                backgroundColor: theme.palette.background.default
            }}>
                {projectTextInput ? (
                    <NewProjectContainer>
                        <TextField
                            label="Enter project name"
                            variant="outlined"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            sx={{
                                width: '100%',
                                maxWidth: 400,
                                '& .MuiOutlinedInput-root': {
                                    color: theme.palette.text.primary,
                                }
                            }}
                            autoFocus
                        />
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleNewProject(setProjects, inputValue, navigate)}
                                sx={{ px: 4 }}
                            >
                                Create
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setProjectTextInput(false)}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </NewProjectContainer>
                ) : (
                    <Button
                        startIcon={<CreateNewFolderIcon />}
                        variant="contained"
                        color="primary"
                        onClick={() => setProjectTextInput(true)}
                        sx={{
                            px: 6,
                            py: 1.5,
                            fontSize: '1rem',
                            textTransform: 'none',
                            borderRadius: 2
                        }}
                    >
                        New Project
                    </Button>
                )}
            </Box>
        </>
    );

    // const chooseProject = (
    //     <>
    //         <Navbar />
    //         <Box sx={{ padding: 2 }}>
    //             <Grid container spacing={2}>
    //                 {projects.map((project, index) => (
    //                     <Grid item xs={12} sm={6} md={3} key={index}>
    //                         <Card>
    //                             <CardMedia
    //                                 component="img"
    //                                 height="140"
    //                                 image={project.thumbnail || `${process.env.PUBLIC_URL}/thumbnail_map.png`}
    //                                 alt={`Project ${index + 1}`}
    //                             />
    //                             <CardContent>
    //                                 <Typography gutterBottom variant="h5" component="div">
    //                                     {project.name}
    //                                 </Typography>
    //                                 <Typography variant="body2" color="text.secondary">
    //                                     <b>Last updated:</b> {project.updated_at}
    //                                 </Typography>
    //                             </CardContent>
    //                             <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
    //                                 <IconButton color="primary" onClick={() => handleChooseOption(project.id, navigate)}>
    //                                     <OpenInBrowserIcon />
    //                                 </IconButton>
    //                                 <IconButton color="secondary" onClick={() => handleDeleteOption(project.id, setProjects)}>
    //                                     <DeleteIcon />
    //                                 </IconButton>
    //                             </Box>
    //                         </Card>
    //                     </Grid>
    //                 ))}
    //             </Grid>
    //             <Box sx={{ textAlign: 'center', marginTop: 2 }}>
    //                 {projectTextInput ? (
    //                     <Box 
    //                         sx={{ 
    //                             display: 'flex', 
    //                             flexDirection: 'column', 
    //                             alignItems: 'center', 
    //                             gap: 2, 
    //                             padding: 2, 
    //                             border: '1px solid #ddd', 
    //                             borderRadius: 2 
    //                         }}
    //                     >
    //                         <TextField
    //                             label="Enter project name"
    //                             variant="outlined"
    //                             value={inputValue}
    //                             onChange={(e) => setInputValue(e.target.value)}
    //                             sx={{ width: '100%', maxWidth: 400 }}
    //                         />
    //                         <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
    //                             <Button
    //                                 variant="contained"
    //                                 onClick={() => handleNewProject(setProjects, inputValue, navigate)}
    //                             >
    //                                 Create
    //                             </Button>
    //                             <Button 
    //                                 variant="outlined" 
    //                                 onClick={() => setProjectTextInput(false)}
    //                             >
    //                                 Cancel
    //                             </Button>
    //                         </Box>
    //                     </Box>
    //                 ) : (
    //                     <Button 
    //                         startIcon={<CreateNewFolderIcon />} 
    //                         variant="contained" 
    //                         onClick={() => setProjectTextInput(true)} 
    //                         sx={{ padding: 1.5 }}
    //                     >
    //                         New Project
    //                     </Button>
    //                 )}
    //             </Box>

    //         </Box>
    //     </>
    // );

    return (
        <>
            {isLoading ? (
                loadingPage()
            ) : null}

            {!project_id?
                chooseProject:
            
            project_id && project ? (
                <MapComponent
                    rasters={rasters}
                    setRasters={setRasters}
                    vectors={vectors}
                    setVectors={setVectors}
                    projectid={project.id}
                    project={project}
                    savetomemory={false}
                    isSharedView={isSharedView}
                />
            ) : 
                loadingPage()
            }
            
        </>
    );
}

export default Project;
