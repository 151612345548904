import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { login } from '../features/user';
import { Button, Snackbar, Alert, TextField, Box, Typography, Link, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

const LoginForm = ({ setShow, setLoginSuccess, handleMouseEnterSignIn, handleMouseLeaveSignIn }) => {
    const { t } = useTranslation();
    const theme = useTheme(); // Obtém o tema atual (light ou dark)
    const [formData, setFormData] = useState({ username: '', password: '' });
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(login(formData))
            .then(data => {
                if (data.meta.requestStatus === 'rejected') {
                    const errors = Object.values(data.payload).flat();
                    errors.forEach(error => {
                        showSnackbar(error, 'error');
                    });
                } else {
                    showSnackbar(t('login.success'), 'success');
                    setLoginSuccess(true);
                }
            })
            .catch(error => {
                console.error('Login error:', error);
            });
    };

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const showSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    return (
        <Box sx={{
            position: "absolute",
            top: "75%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        }}>
            <Typography variant="h4" gutterBottom sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                {t('login.title')}
            </Typography>

            <Box component="form" onSubmit={onSubmit} sx={{
                width: '100%',
                maxWidth: 400,
                p: 3,
                bgcolor: theme.palette.background.paper,
                borderRadius: 2,
                boxShadow: theme.palette.mode === 'light' ? 3 : 5,
            }}>
                <TextField
                    fullWidth
                    label={t('login.username')}
                    name="username"
                    id="username"
                    placeholder={t('login.usernamePlaceholder')}
                    variant="outlined"
                    margin="normal"
                    onChange={onChange}
                />
                <TextField
                    fullWidth
                    label={t('login.password')}
                    name="password"
                    id="password"
                    type="password"
                    placeholder={t('login.passwordPlaceholder')}
                    variant="outlined"
                    margin="normal"
                    onChange={onChange}
                />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
                    <Link href="/register" underline="hover" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                        {t('login.registerLink')}
                    </Link>
                    <Link href="/reset" underline="hover" sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}>
                        {t('login.forgotPassword')}
                    </Link>
                </Box>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onMouseEnter={handleMouseEnterSignIn}
                    onMouseLeave={handleMouseLeaveSignIn}
                    sx={{
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.text.primary,
                        mt: 3,
                        '&:hover': {
                            bgcolor: theme.palette.secondary?.main || theme.palette.primary.dark,
                        }
                    }}
                >
                    {t('login.button')}
                </Button>
                <Button
                    onClick={() => setShow(false)}
                    fullWidth
                    variant="contained"
                    sx={{
                        bgcolor: theme.palette.text.primary,
                        color: theme.palette.background.default,
                        mt: 1,
                        '&:hover': {
                            bgcolor: theme.palette.text.secondary || '#444444',
                        }
                    }}
                >
                    {t('login.cancelButton')}
                </Button>
            </Box>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={5000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default LoginForm;


// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useDispatch } from 'react-redux';
// import { login } from '../features/user';
// import { Button, Snackbar, Alert, TextField, Box, Typography, Link } from "@mui/material";
// import { useTranslation } from "react-i18next";

// const LoginForm = ({
//     setShow,
//     setLoginSuccess,
//     handleMouseEnterSignIn,
//     handleMouseLeaveSignIn
// }) => {
//     const { t } = useTranslation();
//     const [formData, setFormData] = useState({ username: '', password: '' });
//     const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const onSubmit = (e) => {
//         e.preventDefault();
//         dispatch(login(formData))
//             .then(data => {
//                 if (data.meta.requestStatus === 'rejected') {
//                     const errors = Object.values(data.payload).flat();
//                     errors.forEach(error => {
//                         showSnackbar(error, 'error');
//                     });
//                 } else {
//                     showSnackbar(t('login.success'), 'success');
//                     setLoginSuccess(true)
//                     // navigate("/");
//                 }
//             })
//             .catch(error => {
//                 console.error('Login error:', error);
//             });
//     };

//     const onChange = (e) => {
//         setFormData({ ...formData, [e.target.name]: e.target.value });
//     };

//     const showSnackbar = (message, severity) => {
//         setSnackbar({ open: true, message, severity });
//     };

//     const handleCloseSnackbar = () => {
//         setSnackbar({ ...snackbar, open: false });
//     };

//     return (
//         <Box 
//             sx={{
//                 position: "absolute",
//                 top: "75%",
//                 left: "50%",
//                 transform: "translate(-50%, -50%)",
//                 zIndex: 1000,
//                 textAlign: "center",
//                 display: "flex",
//                 flexDirection: "column",
//                 alignItems: "center",
//             }}
//         >
//             <Typography variant="h4" gutterBottom sx={{ color: '#b71c1c', fontWeight: 'bold' }}>
//                 {t('login.title')}
//             </Typography>

//             <Box 
//                 component="form" 
//                 onSubmit={onSubmit} 
//                 sx={{
//                     width: '100%',
//                     maxWidth: 400,
//                     p: 3,
//                     bgcolor: '#ffffff',
//                     borderRadius: 2,
//                     boxShadow: 3,
//                 }}
//             >
//                 <TextField
//                     fullWidth
//                     label={t('login.username')}
//                     name="username"
//                     id="username"
//                     placeholder={t('login.usernamePlaceholder')}
//                     variant="outlined"
//                     margin="normal"
//                     onChange={onChange}
//                 />
//                 <TextField
//                     fullWidth
//                     label={t('login.password')}
//                     name="password"
//                     id="password"
//                     type="password"
//                     placeholder={t('login.passwordPlaceholder')}
//                     variant="outlined"
//                     margin="normal"
//                     onChange={onChange}
//                 />

//                 <Box 
//                     sx={{
//                         display: 'flex', 
//                         justifyContent: 'space-between',
//                         alignItems: 'center',
//                         mt: 2
//                     }}
//                 >
//                     <Link href="/register" underline="hover" sx={{ color: '#b71c1c', fontWeight: 'bold' }}>
//                         {t('login.registerLink')}
//                     </Link>
//                     <Link href="/reset" underline="hover" sx={{ color: '#b71c1c', fontWeight: 'bold' }}>
//                         {t('login.forgotPassword')}
//                     </Link>
//                 </Box>

//                 <Button
//                     type="submit"
//                     fullWidth
//                     variant="contained"
//                     onMouseEnter={handleMouseEnterSignIn}
//                     onMouseLeave={handleMouseLeaveSignIn}
//                     sx={{ 
//                         bgcolor: '#b71c1c', 
//                         color: '#ffffff',
//                         mt: 3,
//                         '&:hover': {
//                             bgcolor: '#d32f2f',
//                         }
//                     }}
//                 >
//                     {t('login.button')}
//                 </Button>
//                 <Button
//                     onClick={()=>setShow(false)}
//                     type="submit"
//                     fullWidth
//                     variant="contained"
//                     sx={{ 
//                         bgcolor: '#000000', 
//                         color: '#ffffff',
//                         mt: 1,
//                         '&:hover': {
//                             bgcolor: '#444444',
//                         }
//                     }}
//                 >
//                     {t('login.cancelButton')}
//                 </Button>
//             </Box>

//             <Snackbar
//                 open={snackbar.open}
//                 autoHideDuration={5000}
//                 onClose={handleCloseSnackbar}
//                 anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
//             >
//                 <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
//                     {snackbar.message}
//                 </Alert>
//             </Snackbar>
//         </Box>
//     );
// };

// export default LoginForm;
