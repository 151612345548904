import React from "react";
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Card,
    CardContent,
    createTheme,
    ThemeProvider,
    CssBaseline,
} from "@mui/material";

const ContactPage = () => {
    // Custom Dark Mode Theme for Contact Page
    const darkTheme = createTheme({
        palette: {
            mode: "dark",
            background: {
                default: "#121212",
                paper: "#1E1E1E",
            },
            text: {
                primary: "#FFFFFF",
            },
            primary: {
                main: "#90CAF9",
            },
        },
    });

    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline />
            <Box
                sx={{
                    backgroundColor: darkTheme.palette.background.default,
                    color: darkTheme.palette.text.primary,
                    minHeight: "100vh",
                    padding: "2rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {/* Page Header */}
                <Typography
                    variant="h3"
                    component="h1"
                    gutterBottom
                    sx={{
                        fontWeight: 600,
                        textAlign: "center",
                    }}
                >
                    Contact Us
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    gutterBottom
                    sx={{
                        textAlign: "center",
                        maxWidth: "600px",
                        marginBottom: "2rem",
                    }}
                >
                    We'd love to hear from you! Whether you have questions, feedback, or
                    need support, feel free to reach out.
                </Typography>

                <Grid container spacing={4} justifyContent="center">
                    {/* Contact Form */}
                    <Grid item xs={12} md={6}>
                        <Card
                            sx={{
                                backgroundColor: darkTheme.palette.background.paper,
                                boxShadow: darkTheme.shadows[4],
                                padding: "1.5rem",
                            }}
                        >
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Send a Message
                                </Typography>
                                <Box
                                    component="form"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "1rem",
                                        marginTop: "1rem",
                                    }}
                                >
                                    <TextField
                                        label="Your Name"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        InputProps={{
                                            sx: {
                                                color: darkTheme.palette.text.primary,
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Your Email"
                                        type="email"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        InputProps={{
                                            sx: {
                                                color: darkTheme.palette.text.primary,
                                            },
                                        }}
                                    />
                                    <TextField
                                        label="Message"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                        fullWidth
                                        required
                                        InputProps={{
                                            sx: {
                                                color: darkTheme.palette.text.primary,
                                            },
                                        }}
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{
                                            alignSelf: "flex-start",
                                        }}
                                    >
                                        Send Message
                                    </Button>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card
                            sx={{
                                backgroundColor: darkTheme.palette.background.paper,
                                boxShadow: darkTheme.shadows[4],
                                padding: "1.5rem",
                            }}
                        >
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Get in Touch
                                </Typography>
                                <Box
                                    component="ul"
                                    sx={{
                                        listStyleType: "none",
                                        padding: 0,
                                        marginTop: "1rem",
                                        lineHeight: 1.8,
                                    }}
                                >
                                    <li>
                                        <Typography>
                                            <strong>Email:</strong>{" "}
                                            <a
                                                href="mailto:admin@webgisproject.com"
                                                style={{ color: darkTheme.palette.primary.main }}
                                            >
                                                admin@webgisproject.com
                                            </a>
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            <strong>Phone:</strong>{" "}
                                            <a
                                                href="tel:+5549999175593"
                                                style={{ color: darkTheme.palette.primary.main }}
                                            >
                                                +55 (49) 99917-5593
                                            </a>
                                        </Typography>
                                    </li>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
};

export default ContactPage;
