import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Select, FormControl, InputLabel, MenuItem as MuiMenuItem } from '@mui/material';
import i18n from '../../../i18n/i18n';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const ProfileSettings = ({onThemeChange}) => {
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
    const [selectedTheme, setSelectedTheme] = useState('light'); // Default to light theme
    const { t } = useTranslation();

    const handleLanguageChange = (event) => {
        const lng = event.target.value;
        setSelectedLanguage(lng);
        i18n.changeLanguage(lng);
    };

    const handleThemeChange = (event) => {
        const theme = event.target.value;
        setSelectedTheme(theme);
        onThemeChange(theme)
        console.log('Theme changed to:', theme); // Replace this with actual theme implementation
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableBody>
                    <StyledTableRow key="1">
                        <StyledTableCell component="th" scope="row">
                            {t('language')}
                        </StyledTableCell>
                        <StyledTableCell>
                            <FormControl fullWidth>
                                <InputLabel id="language-select-label">{t('language')}</InputLabel>
                                <Select
                                    labelId="language-select-label"
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                >
                                    <MuiMenuItem value="en-US">English</MuiMenuItem>
                                    <MuiMenuItem value="pt-BR">Portuguese</MuiMenuItem>
                                </Select>
                            </FormControl>
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow key="2">
                        <StyledTableCell component="th" scope="row">
                            {t('theme')}
                        </StyledTableCell>
                        <StyledTableCell>
                            <FormControl fullWidth>
                                <InputLabel id="theme-select-label">{t('theme')}</InputLabel>
                                <Select
                                    labelId="theme-select-label"
                                    value={selectedTheme}
                                    onChange={handleThemeChange}
                                >
                                    <MuiMenuItem value="light">{t('light')}</MuiMenuItem>
                                    <MuiMenuItem value="dark">{t('dark')}</MuiMenuItem>
                                </Select>
                            </FormControl>
                        </StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
// import React, { useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import Table from '@mui/material/Table';
// import { styled } from '@mui/material/styles';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// import { Select, FormControl, InputLabel, MenuItem as MuiMenuItem } from '@mui/material';
// import i18n from '../../../i18n/i18n';

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//     [`&.${tableCellClasses.head}`]: {
//         backgroundColor: theme.palette.common.black,
//         color: theme.palette.common.white,
//     },
//     [`&.${tableCellClasses.body}`]: {
//         fontSize: 14,
//     },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//     '&:nth-of-type(odd)': {
//         backgroundColor: theme.palette.action.hover,
//     },
//     '&:last-child td, &:last-child th': {
//         border: 0,
//     },
// }));

// export const ProfileSettings = () => {
//     const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
//     const { t } = useTranslation();

//     const handleLanguageChange = (event) => {
//         const lng = event.target.value;
//         setSelectedLanguage(lng);
//         i18n.changeLanguage(lng);
//     };


//     return (
//         <>
//         <TableContainer component={Paper}>
//             <Table sx={{ minWidth: 700 }} aria-label="customized table">
//                 <TableBody>
//                     <StyledTableRow key="1">
//                         <StyledTableCell component="th" scope="row">
//                         <InputLabel id="language-select-label">{t('language')}</InputLabel>
//                         </StyledTableCell>
//                         <StyledTableCell component="th" scope="row">
//                             <FormControl fullWidth>
//                                     <InputLabel id="language-select-label">{t('language')}</InputLabel>
//                                     <Select
//                                         labelId="language-select-label"
//                                         value={selectedLanguage}
//                                         onChange={handleLanguageChange}
//                                     >
//                                         <MuiMenuItem value="en-US">English</MuiMenuItem>
//                                         <MuiMenuItem value="pt-BR">Portuguese</MuiMenuItem>
//                                     </Select>
//                             </FormControl>
//                         </StyledTableCell>
//                     </StyledTableRow>
//                 </TableBody>
//             </Table>
//         </TableContainer>
//         </>
//     )
// }
