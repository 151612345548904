import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import NavbarComponent from './include/Navbar';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { 
  Button, 
  CircularProgress, 
  Box, 
  Typography, 
  Divider, 
  useTheme,
  styled 
} from '@mui/material';
import {
  Person as PersonIcon,
  Settings as SettingsIcon,
  CreditCard as PlanIcon,
  AccountCircle as AccountIcon,
  // HomeIcon
} from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import { ProfileSettings } from './utils/profile/Settings';
import { ProfileAccount } from './utils/profile/Account';
import { ProfileUserInformation } from './utils/profile/UserInformation';
import { ProfilePlan } from './utils/profile/Plan';
import { getMembership } from "../features/user";

const SidebarButton = styled(Button)(({ theme, active }) => ({
  justifyContent: 'flex-start',
  textTransform: 'none',
  padding: theme.spacing(1.5, 3),
  margin: theme.spacing(0.5, 0),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: active ? theme.palette.action.selected : 'transparent',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Dashboard = ({ onThemeChange }) => {
  const theme = useTheme();
  const { user, loading, membership } = useSelector(state => state.user);
  const [activeSection, setActiveSection] = useState('userInformation');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const profile = user && user.profile;

  useEffect(() => {
    if (user) {
      dispatch(getMembership());
    }
  }, [user, dispatch]);

  const renderActiveSection = () => {
    switch (activeSection) {
      case 'userInformation':
        return <ProfileUserInformation user={user} profile={profile} />;
      case 'settings':
        return <ProfileSettings onThemeChange={onThemeChange} />;
      case 'account':
        return <ProfileAccount />;
      case 'plan':
        return <ProfilePlan user={user} profile={profile} membership={membership.data} />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* <NavbarComponent /> */}
      {loading || user === null ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ 
          display: 'flex', 
          minHeight: '100vh', 
          backgroundColor: theme.palette.background.default 
        }}>
          <Box sx={{ 
            width: 280, 
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(3, 2),
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1)
          }}>
            <Typography variant="h6" sx={{ 
              color: theme.palette.primary.contrastText,
              padding: theme.spacing(1, 2),
              marginBottom: theme.spacing(1)
            }}>
              {t('user_profile')}
            </Typography>
            <Divider sx={{ borderColor: theme.palette.primary.light, mb: 2 }} />
            
            <SidebarButton
              startIcon={<PersonIcon sx={{ color: theme.palette.primary.contrastText }} />}
              active={activeSection === 'userInformation'}
              onClick={() => setActiveSection('userInformation')}
            >
              <Typography 
                variant="body1" 
                sx={{ color: theme.palette.primary.contrastText }}>
                {t('user_information')}
              </Typography>
            </SidebarButton>

            <SidebarButton
              startIcon={<SettingsIcon sx={{ color: theme.palette.primary.contrastText }} />}
              active={activeSection === 'settings'}
              onClick={() => setActiveSection('settings')}
            >
              <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>
                {t('settings')}
              </Typography>
            </SidebarButton>

            <SidebarButton
              startIcon={<PlanIcon sx={{ color: theme.palette.primary.contrastText }} />}
              active={activeSection === 'plan'}
              onClick={() => setActiveSection('plan')}
            >
              <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>
                {t('plan')}
              </Typography>
            </SidebarButton>

            <SidebarButton
              startIcon={<AccountIcon sx={{ color: theme.palette.primary.contrastText }} />}
              active={activeSection === 'account'}
              onClick={() => setActiveSection('account')}
            >
              <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>
                {t('account')}
              </Typography>
            </SidebarButton>
            {/* <Box sx={{ 
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: theme.spacing(2),
              borderTop: `1px solid ${theme.palette.primary.light}`
            }}> */}
              <SidebarButton
                sx = {{
                  position: 'absolute',
                  bottom: 0,
                  left: 20,
                  width: 280, 
                  backgroundColor: theme.palette.primary.main,
                  padding: theme.spacing(3, 2),
                  display: 'flex',
                }}
                // fullWidth
                startIcon={<HomeIcon sx={{ color: theme.palette.primary.contrastText }} />}
                onClick={() => {
                  window.location.href = '/'; 
                }}
              >
                <Typography variant="body1" sx={{ color: theme.palette.primary.contrastText }}>
                  {t('homepage')}
                </Typography>
              </SidebarButton>
            {/* </Box> */}
          </Box>
          

          {/* Main Content */}
          <Box sx={{ 
            flex: 1, 
            padding: theme.spacing(4),
            maxWidth: 1200,
            margin: '0 auto',
            // backgroundColor:"#8272E0"
          }}>
            {renderActiveSection()}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Dashboard;





















// import React, { useState } from 'react';
// import { useEffect } from 'react';
// import { useDispatch } from 'react-redux';
// import NavbarComponent from './include/Navbar';
// import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { Button, CircularProgress, Box } from '@mui/material';
// import { ProfileSettings } from './utils/profile/Settings';
// import { ProfileAccount } from './utils/profile/Account';
// import { ProfileUserInformation } from './utils/profile/UserInformation';
// import { ProfilePlan } from './utils/profile/Plan';
// import {getMembership} from "../features/user"

// const Dashboard = ({onThemeChange}) => {
//   const { user, loading,membership } = useSelector(state => state.user);
//   const [activeSection, setActiveSection] = useState('userInformation');
//   const { t } = useTranslation();
//   const dispatch = useDispatch();
//   const profile = user && user.profile;

//   useEffect(() => {
//     if (user) {
//       dispatch(getMembership()); 
//     }
//   }, [user, dispatch]);

//   const renderActiveSection = () => {
//     switch (activeSection) {
//       case 'userInformation':
//         return <ProfileUserInformation
//           user={user}
//           profile={profile}
//         />

//       case 'settings':
//         return <ProfileSettings onThemeChange={onThemeChange}/>;

//       case 'account':
//         return <ProfileAccount/>

//       case 'plan':
//         return <ProfilePlan
//           user={user}
//           profile={profile}
//           membership={membership.data}
//         />

//       default:
//         return null;
//     }
//   };

//   console.log(membership)

//   return (
//     <>
//       <NavbarComponent />
//       {loading || user === null ? (
//         <Box >
//           <CircularProgress />
//         </Box>
//       ) : (
//         <Box sx={{ display: 'flex', height: '100vh' }}>
//           <Box sx={{ 
//               width: 250, 
//               backgroundColor: "#8272E0",//#'#f4f4f4', 
//               padding: 2, 
//               boxShadow: 1,
              
//             }}>
//             <Button 
//               sx={{
//                 color:"white",
//                 textAlign: 'left', 
//                 justifyContent: 'flex-start', 
//                 textTransform: 'none',
//               }} 
//               fullWidth variant="text" onClick={() => setActiveSection('userInformation')}>
//               {t('user_information')}
//             </Button>
//             <Button 
//               sx={{
//                 color:"white",
//                 textAlign: 'left', 
//                 justifyContent: 'flex-start', 
//                 textTransform: 'none',
//               }} 
//               fullWidth variant="text" onClick={() => setActiveSection('settings')}>
//               {t('settings')}
//             </Button>
//             <Button 
//               sx={{
//                 color:"white",
//                 textAlign: 'left', 
//                 justifyContent: 'flex-start', 
//                 textTransform: 'none',
//               }} 
//             fullWidth variant="text" onClick={() => setActiveSection('plan')}>
//               {t('plan')}
//             </Button>
//             <Button 
//               sx={{
//                 color:"white",
//                 textAlign: 'left', 
//                 justifyContent: 'flex-start', 
//                 textTransform: 'none',
//               }} 
//             fullWidth variant="text" onClick={() => setActiveSection('account')}>
//               {t('account')}
//             </Button>
//           </Box>

//           <Box sx={{ flex: 1, padding: 3 }}>
//             {renderActiveSection()}
//           </Box>
//         </Box>
//       )}
//     </>
//   );
// };

// export default Dashboard;
