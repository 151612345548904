import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

export const register = createAsyncThunk(
	'users/register',
	async ({ username, email, password, password2 }, thunkAPI) => {
		const body = JSON.stringify({
			username,
			email,
			password,
			password2,
		});

		try {
			const res = await fetch(
				`${process.env.REACT_APP_API_URL}api/users/register/`
				, {
					method: 'POST',
					headers: {
						Accept: 'application/json',
						"Content-Type": 'application/json',
					},
					body,
				});

			const data = await res.json();

			if (res.status === 201) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const update = createAsyncThunk(
	'users/update',
	async ({ first_name, last_name, profile_picture, access }, thunkAPI) => {
		const formData = new FormData();
		formData.append('first_name', first_name);
		formData.append('last_name', last_name);
		formData.append('profile_picture', profile_picture);

		try {
			const res = await fetch(`${process.env.API_URL}api/users/update/`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${access}`,
				},
				body: formData,
			});
			const data = await res.json();

			if (res.status === 201) {
				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);


const getUser = createAsyncThunk('users/me',
	async (_, thunkAPI) => {
		try {
			const res = await fetch(`${process.env.REACT_APP_API_URL}api/users/me/`
				, {
					method: 'GET',
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${Cookies.get('access_token')}`,
					},
				}
			);
			const data = await res.json();

			if (res.status === 200) {
				return data;
			} else {
				const { dispatch } = thunkAPI;

				dispatch(logout());
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	});


export const getMembership = createAsyncThunk('users/membership',
	async (_, thunkAPI) => {
		try {
			const res = await fetch(`${process.env.REACT_APP_API_URL}api/plans/membership/`
				, {
					method: 'GET',
					headers: {
						Accept: 'application/json',
						Authorization: `Bearer ${Cookies.get('access_token')}`,
					},
				}
			);
			const data = await res.json();

			if (res.status === 200) {
				return data;
			} else {
				// const { dispatch } = thunkAPI;
				// dispatch(logout());
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	});


export const login = createAsyncThunk(
	'users/login',
	async ({ username, password }, thunkAPI) => {
		const body = JSON.stringify({
			username,
			password,
		});

		try {
			const res = await fetch(`${process.env.REACT_APP_API_URL}api/users/token/`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			const data = await res.json();

			if (res.status === 200) {
				const { dispatch } = thunkAPI;

				Cookies.set('access_token', data.access, { expires: 30 });
				Cookies.set('refresh_token', data.refresh, { expires: 30 });

				dispatch(getUser());

				return data;
			} else {
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

export const refreshToken = createAsyncThunk(
	'user/refreshToken',
	async (_, { rejectWithValue }) => {
		try {
			const body = JSON.stringify({
				refresh: Cookies.get('refresh_token'),
			});

			const response = await fetch(`${process.env.REACT_APP_API_URL}api/users/token/refresh/`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body,
			});

			if (!response.ok) {
				// dispatch(logout())
				throw new Error('Failed to refresh token');
			}

			const data = await response.json();
			Cookies.set('access_token', data.access, { expires: 30 });

			return data;
		} catch (error) {
			// dispatch(logout())
			return rejectWithValue(error.message);
		}
	}
);

export const checkAuth = createAsyncThunk(
    'users/verify',
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const body = JSON.stringify({
                token: Cookies.get('access_token'),
            });

            const res = await fetch(`${process.env.REACT_APP_API_URL}api/users/token/verify/`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body,
            });

            if (res.status === 200) {
                const userRes = await dispatch(getUser()).unwrap();
                if (userRes) {
                    return userRes; 
                } else {
                    return rejectWithValue("Failed to fetch user data");
                }
            } else if (res.status === 401) {
                const refreshResponse = await dispatch(
					refreshToken()
				).unwrap();

                if (refreshResponse) {
                    const newToken = Cookies.get('access_token');
					const body = JSON.stringify({
						token: newToken,
					});
                    const retryRes = await fetch(
		`${process.env.REACT_APP_API_URL}api/users/token/verify/`, {
                        method: 'POST',
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            // Authorization: `Bearer ${newToken}`,
                        },
                        body,
                    });

                    if (retryRes.status === 200) {
                        const userRes = await dispatch(getUser()).unwrap(); // Wait for `getUser` to resolve
                        return userRes;
                    } else {
                        return rejectWithValue("Token refresh failed.");
                    }
                } else {
                    return rejectWithValue("Token refresh failed.");
                }
            } else {
                const data = await res.json();
                return rejectWithValue(data);
            }
        } catch (err) {
            dispatch(logout());
            return rejectWithValue(err.message);
        }
    }
);


// export const checkAuth = createAsyncThunk(
//     'users/verify',
//     async (_, { dispatch, rejectWithValue }) => {
//         try {
//             const body = JSON.stringify({
//                 token: Cookies.get('access_token'),
//             });

//             const res = await fetch(`${process.env.REACT_APP_API_URL}api/users/token/verify/`, {
//                 method: 'POST',
//                 headers: {
//                     Accept: 'application/json',
//                     'Content-Type': 'application/json',
//                 },
//                 body,
//             });

//             if (res.status === 200) {
//                 // Token is valid, now attempt to fetch the user
//                 const userRes = await dispatch(getUser()).unwrap(); // Wait for `getUser` to resolve
//                 if (userRes) {
//                     return userRes; // Authentication successful
//                 } else {
//                     return rejectWithValue("Failed to fetch user data");
//                 }
//             } else {
//                 const data = await res.json();
//                 return rejectWithValue(data);
//             }
//         } catch (err) {
//             dispatch(logout());
//             return rejectWithValue(err.message);
//         }
//     }
// );

export const logout = createAsyncThunk('users/logout', async (_, thunkAPI) => {
	try {
		Cookies.remove('access_token');
		Cookies.remove('refresh_token');
	} catch (err) {
		return thunkAPI.rejectWithValue(err.response.data);
	}
});


export const deleteUser = createAsyncThunk(
	'users/deleteUser',
	async (userId, thunkAPI) => {
		try {

			// const body = JSON.stringify({
			// 	token:Cookies.get('access_token'),
			// });

			const res = await fetch(`${process.env.REACT_APP_API_URL}api/users/delete/${userId}/`, {
				method: 'DELETE',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${Cookies.get('access_token')}`
				},
				//   body,
			});

			if (res.ok) {
				// Deletion was successful
				return userId;
			} else {
				const data = await res.json();
				return thunkAPI.rejectWithValue(data);
			}
		} catch (err) {
			return thunkAPI.rejectWithValue(err.response.data);
		}
	}
);

const initialState = {
	isAuthenticated: false,
	user: null,
	loading: true,//false,
	registered: false,
	membership:null
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		resetRegistered: state => {
			state.registered = false
		},
	},
	extraReducers: builder => {
		builder
			.addCase(register.pending, state => {
				state.loading = true;
			})
			.addCase(register.fulfilled, state => {
				state.loading = false;
				state.registered = true;
			})
			.addCase(register.rejected, state => {
				state.loading = false;
			})
			.addCase(login.pending, state => {
				state.loading = true;
			})
			.addCase(login.fulfilled, state => {
				state.loading = false;
				state.isAuthenticated = true;
			})
			.addCase(login.rejected, state => {
				state.loading = false;
			})
			.addCase(getMembership.pending, state => {
				state.loading = true;
			})
			.addCase(getMembership.fulfilled, (state, action) => {
				state.loading = false;
				state.membership = action.payload;
			})
			.addCase(getMembership.rejected, state => {
				state.loading = false;
			})
			.addCase(getUser.pending, state => {
				state.loading = true;
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload;
			})
			.addCase(getUser.rejected, state => {
				state.loading = false;
			})
			.addCase(checkAuth.pending, state => {
				state.loading = true;
			})
			.addCase(checkAuth.fulfilled, state => {
				state.loading = false;
				state.isAuthenticated = true;
			})
			.addCase(checkAuth.rejected, state => {
				state.loading = false;
			})
			.addCase(logout.pending, state => {
				state.loading = true;
			})
			.addCase(logout.fulfilled, state => {
				state.loading = false;
				state.isAuthenticated = false;
				state.user = null;
			})
			.addCase(logout.rejected, state => {
				state.loading = false;
			})
			.addCase(update.pending, state => {
				state.loading = true;
			})
			.addCase(update.fulfilled, state => {
				state.loading = false
			})
			.addCase(update.rejected, state => {
				state.loading = false
			})
			;
	}
})

export const { resetRegistered } = userSlice.actions;
export default userSlice.reducer;